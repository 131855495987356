import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes} from '../../../utils/Variables';
import {upperCase, groupByMultiple} from '../../../utils';

class Sidebar extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	
	render(){
		
		
		return(
			  <div>
			  {this.props.activeMenu ? 
				<Row>
					<Col md={12} >									
						 <div className="de-left-tit">
							<h4><span className="ion-ios7-arrow-right" style={{
									color:'#fff',
									fontSize: '20px',
									fontWeight: '600',
									marginRight: '5px',
									}} ></span> {this.props.activeMenuTitle}</h4>
						</div>
						<div className="ho-event">
							<ul className="mmm-sidebar-menu" >
							{this.props.activeMenu.map((item,i)=>{
								return <li key={i} >
									<div className="ho-ev-link ho-ev-link-full">
										<NavLink to={item.link}   >
											{item.name}
										</NavLink>
									</div>
								</li>;
							})
							}
							</ul>
						</div>								
					</Col>
				</Row>
			  : null }
				<Row>
					<Col md={12} >									
						 <div className="de-left-tit">
							<h4><span className="ion-ios7-arrow-right" style={{
									color:'#fff',
									fontSize: '20px',
									fontWeight: '600',
									marginRight: '5px',
									}} ></span> ACADEMICS</h4>
						</div>
						<div className="ho-event">
							<ul className="mmm-sidebar-menu" >
							{DT_Academics.map((item,i)=>{
								return <li key={i} >
									<div className="ho-ev-link ho-ev-link-full">
										<NavLink to={item.link}  >
											{item.name}
										</NavLink>
									</div>
								</li>;
							})
							}
							</ul>
						</div>	
					
					</Col>
				</Row>
		</div>
						
		);
	}
	
}
export default Sidebar;

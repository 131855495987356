import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

class PdfViewer extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	componentDidMount(){
		
		console.log(this.props.match.params);
	}
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn pt-30">
					
					
					
				</div>
			</section>
		);
	}
	
}
export default PdfViewer;

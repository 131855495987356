import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';

import {Header, Footer, Slider} from '../views/elements';
import {Api} from '../utils/Variables';
import API from '../utils/API';
import Cookies from 'universal-cookie';
import routes from '../views';
import {setCookie} from '../utils';

class SiteLayout extends Component{
	constructor(props){
		super(props);
		this.state = {
			siteInfo: [],
		}
	}
	
	componentDidMount(){
		this.loadSiteinfo();
	}
	
	loadSiteinfo(){
		try{
			
			API.get('v1/settings/me/1').then(res=>{
				if(res['data'].status=='1')
				{
					setCookie('site',JSON.stringify(res['data'].data));
					this.setState({siteInfo: res['data'].data});
				}
			});
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	
	render(){
		
		
		return(
			<div>
				<Header routes={routes} />
				
					<div className="app-page-content">
						<Switch>
						{routes.map((page,key)=>(
							<Route exact path={page.path}  component={page.component} key={key} />
						))}
						</Switch>
					</div>
					
				<Footer/>
				
			</div>
		);
	}
	
}
export default SiteLayout;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Tabs, Tab} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import FOUNDER_IMAGE from '../../../assets/img/sarah-1.png';
import IMG_WEATHER from '../../../assets/img/weather.png';

class Founder extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		this.loadAnnounceMents();
	}
	
	loadAnnounceMents(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('type','announcement');
			form.append('expired','1');
			API.post('site/v1/news/list',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	marqueeStop(){
		document.getElementById('mmm_an_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_an_scroll_mq').start();
	}
	
	render(){
		
		
		return(
			<section className="c-all h-quote py-0"  style={{backgroundImage:`url(${IMG_WEATHER})`}} >
				<div className="container">
					<div className="col-md-6 col-sm-12 col-xs-12 pt-50 pb-30 " >
						<div className="all-title quote-title qu-new">
							<h5 style={{marginBottom:'0px'}} >Founder</h5>
							
							<div className="text-white" >
								<img src={FOUNDER_IMAGE} className="img-thumbnail circle" style={{width: '180px', float:'left'}} />
								<h3>Sarah Tucker</h3>
								Sarah Tucker College was established in 1895 by the Christian Missionary Society, as the first college for women in south India. It is named after Sarah Tucker, a physically challenged woman confined to her room in England. She was moved by the reports of the appalling condition of the women in this region due to their illiteracy and stepped in to provide education for them. The institution began with four students. 
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 col-xs-12">
						<div >
							<ul className="nav nav-tabs">
								<li className="active"><a><span>Announcements</span></a></li>
							</ul>
							<div style={{background: 'white',padding: '10px', minWidth: '122%' }}>
								<marquee id="mmm_an_scroll_mq" direction="up" style={{height:'265px'}} scrollamount="4" onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart}  >
									<ul className="list-style-none" >
									{this.state.dataList.map((item,i)=>{
										return <li key={i} >
											{item.title}
											<br />
											<div dangerouslySetInnerHTML={{__html: item.content}} />
										</li>;
									})
									}
									</ul>
								</marquee>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
	
}
export default Founder;

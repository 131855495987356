import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl} from '../../../utils';

class TopHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	marqueeStop(){
		document.getElementById('top_new_an').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('top_new_an').start();
	}
	
	render(){
		
	
		return(
			<div>
				
				 <div className="ed-top">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<marquee width="100%" direction="left" style={{color: '#fff', paddingTop: '10px'}} height="40" scrollamount="3" id="top_new_an" onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart}  >
									<img src="https://res.cloudinary.com/dzyixy4du/image/upload/v1595394292/New-Animatinn-GIF.gif" style={{width: '67px'}} /> 
									<b>The National Assessment and Accreditation Council (NAAC) has accredited our college with <span style={{fontSize: '18px'}} > A+ </span> Grade.	</b>
									{/*&emsp;
									<a href="https://epay.sarahtuckercollege.edu.in/" style={{color:'#fff', fontWeight:'600'}} target="_blank">
									Dear Students, The Graduation Day will be held on Tuesday, 29th March 2022 at 9.00 am in the Centenary Auditorium of our college.  You are asked to register your names on or before 20-03-2022 through the given web link: epay.sarahtuckercollege.edu.in/
		</a>*/}
									
								</marquee>
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		);
	}
	
}
export default TopHeader;
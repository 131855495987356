import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import MarqueeAnnouncement from './MarqueeAnnouncement';

import BG_VISION from '../../../assets/img/bg-vision.jpg';

const styles = {
	visionCss: {
		borderTop: '3px solid #FAB0D5',
		borderRadius: '5px',
		minHeight: '260px',
		maxHeight: '261px',
		/*backgroundImage: `url(${BG_VISION})`,
		backgroundSize: '100% 100%',*/
	},
	visionText: {
		padding: '10px',
		fontFamily: 'Times New Roman',
		fontSize: '14px',
		lineHeight: '25px',
		textAlign: 'justify',
	},
	missionCss: {
		borderTop: '3px solid #15EFEE',
		borderRadius: '5px',
		minHeight: '260px',
		maxHeight: '261px',
		textAlign: 'center',
		/*backgroundImage: `url(${BG_VISION})`,
		backgroundSize: '100% 100%',*/
	},
	announceCss: {
		background: '#fff',
		padding: '15px',
		borderTop: '3px solid #FAB0D5',
		borderRadius: '5px',
		minHeight: '260px',
		maxHeight: '261px',
		/*backgroundImage: `url(${BG_VISION})`,
		backgroundSize: '100%',
		backgroundRepeat: 'no-repeat',*/
	},
}

class VisionBox extends Component{
	constructor(props){
		super(props);
		this.state = {
			activeTab: 'vision',
		}
	}
	
	componentDidMount(){

	}
	
	
	
	render(){
		
		
		return(
			<section className="bg-light pt-40" >
				<div className="container" >
					<Row>
						<Col md={3} >
							<div className="home-top-cour shadow-lg" style={styles.visionCss} >
								<h4 className="text-center" style={{fontFamily: 'Times New Roman'}}  >VISION</h4>
								<div style={styles.visionText} >
									&emsp; To be an institute of excellence where young girls can achieve full potential in their academic, creative, physical and spiritual development so as to become empowered and worthy citizens capable of nuturing the society and the nation.
								</div>
							</div>
						</Col>
						<Col md={3} >
							<div className="home-top-cour p-20 shadow-lg" style={styles.missionCss} >
								<h4 className="text-center"  style={{fontFamily: 'Times New Roman'}} >MISSION</h4>
								<div style={styles.visionText} >
									Service through Knowledge.
									<br />
									<h5>Motto</h5>
									“So run that ye may obtain the incorruptible crown”
								</div>
							</div>
						</Col>
						<Col md={6} >
							<div className="home-top-cours shadow-lg" style={styles.announceCss} >
								<h4 className="text-center" style={{fontFamily: 'Times New Roman'}}  >ANNOUNCEMENTS</h4>
								<div style={styles.visionText} >
									<MarqueeAnnouncement />
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</section>
		);
	}
	
}
export default VisionBox;

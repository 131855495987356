import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';

class Contact extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn pt-30">
					<Row>
							
							<Col md={8} >
							
							</Col>
							
							<Col md={4} >
								
								<div className="bot-gal h-blog ho-event">
										<h4>News & Event</h4>
										<div className="ho-event">
											<ul>
												<li>
													<div className="ho-ev-date"><span>07</span><span>jan,2018</span>
													</div>
													<div className="ho-ev-link">
														<a href="events.html">
															<h4>Latinoo College Expo 2018</h4>
														</a>
														<p>Nulla at velit convallis, venenatis lacus quis, efficitur lectus.</p>
														<span>9:15 am – 5:00 pm</span>
													</div>
												</li>
												<li>
													<div className="ho-ev-date"><span>12</span><span>jan,2018</span>
													</div>
													<div className="ho-ev-link">
														<a href="events.html">
															<h4>Training at Team Fabio Clemente</h4>
														</a>
														<p>Nulla at velit convallis venenatis.</p>
														<span>9:15 am – 5:00 pm</span>
													</div>
												</li>
												<li>
													<div className="ho-ev-date"><span>26</span><span>jan,2018</span>
													</div>
													<div className="ho-ev-link">
														<a href="events.html">
															<h4>Nulla at velit convallis</h4>
														</a>
														<p>Nulla at velit convallis, venenatis lacus quis, efficitur lectus.</p>
														<span>9:15 am – 5:00 pm</span>
													</div>
												</li>
												<li>
													<div className="ho-ev-date"><span>18</span><span>jan,2018</span>
													</div>
													<div className="ho-ev-link">
														<a href="events.html">
															<h4>Admissions Information Session and Tour</h4>
														</a>
														<p>Nulla at velit convallis, venenatis lacus quis, efficitur lectus.</p>
														<span>9:15 am – 5:00 pm</span>
													</div>
												</li>
											</ul>
										</div>
									</div>
									
							</Col>
							
					</Row>
				</div>
			</section>
		);
	}
	
}
export default Contact;

import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import axios from 'axios';

import Sidebar from './Sidebar';

class Course extends Component{
	constructor(props){
		super(props);
		this.state = {
			courseList: [],
			courseView: [],
			courseTypeGroup: [],
			batchList: [],
			currentCourse: [],
			actieTab: 'about',
			courseId: this.props.match.params.cid,
			pageData: [],
		}
	}
	
	componentDidMount(){
		
		this.loadCourseInfo();
		this.loadPageData();
	}
	

	componentWillReceiveProps(props){
		
		try{
			
			this.state.courseId = props.match.params.cid;
			this.state.activeTab = 'about';
			
			this.loadCourseInfo();
			this.loadPageData();
		}
		catch(error){
			
		}
	}
	
	loadCourseInfo(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.AcademicPath+'course/getdata/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, currentCourse: res['data'].data});
				}
				else
				{
					this.setState({showLoader: false, currentCourse: []});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadPageData(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/course/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, pageData: res['data'].pagedata});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	displayPageData(){
		try{
			
			//console.log(this.state.pageData);
			var actieTab = this.state.actieTab;
			var d = this.state.pageData.filter(function(obj){
				return(upperCase(obj.category)==upperCase(actieTab));
			});
			
			if(Object.keys(d).length>0)
			{
				return d[0]['pagecontent'];
			}
			else
				return '';
		}
		catch(error){
			return '';
		}
	}
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn pt-30">
					
					<Row>
						<Col md={3} >
						{this.state.showLoader ? <CircleSlowLoader /> :
							<Row>
								<Col md={12} >
									<div className="de-left-tit">
										<h4><span className="ion-ios7-arrow-right" style={{
												color:'#fff',
												fontSize: '20px',
												fontWeight: '600',
												marginRight: '5px',
												}} ></span> MENU</h4>
									</div>
									<div className="ho-event">
										<ul className="mmm-sidebar-menu" >
										{DT_CourseMenu.map((item,i)=>{
											return <li key={i} >
												<div className="ho-ev-link ho-ev-link-full">
													<a href="javascript:;"  onClick={()=>{this.setState({actieTab: item.link})}}
													className={this.state.actieTab==item.link ? 'text-danger' : ''} >
														{item.name}
													</a>
												</div>
											</li>;
										})
										}
										</ul>
									</div>	
								</Col>
							</Row>
							}
							
						</Col>
						
						<Col md={6} >
						
							{this.state.showLoader ? <CircleSlowLoader />
							
							:
							<div>
								
								<div style={{textAlign:'center', borderBottom: '1px solid #9e9e9e'}} className="pb-10 con-title" > 
								<h2>{upperCase(this.state.currentCourse.coursename)} 
										&nbsp;<span>{upperCase(this.state.currentCourse.name)}</span> </h2>
                                <span>{capitalizeFirst(this.state.currentCourse.coursetype)}</span>
								</div>
								
								{this.state.actieTab=='about' ? 
									<div>
										<h4 className="my-15" >About</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='vision' ? 
									<div>
										<h4 className="my-15" >Vision & Mission</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='syllabus' ? 
									<div>
										<h4 className="my-15" >Syllabus</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								
								{this.state.actieTab=='courseoutcomes' ? 
									<div>
										<h4 className="my-15" >POS & Course Outcomes</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='teachingplan' ? 
									<div>
										<h4 className="my-15" >POS & Teaching Plan</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='awards' ? 
									<div>
										<h4 className="my-15" >Awards</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
							</div>
							}
							
						</Col>
						
						<Col md={3} >
							
							<Sidebar activeMenu={DT_Programmes} activeMenuTitle="PROGRAMMES" />
							
						</Col>
					</Row>
					
				</div>
			</section>
		);
	}
	
}
export default Course;

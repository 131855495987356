import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';
import {getLink} from '../../../utils';

class TopScroll extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
		}
	}
	
	componentDidMount(){
		
		this.loadNews();
	}
	
	loadNews()
	{
		try{
			API.get('site/v1/topscroll').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data});
				}
			});
		}
		catch(error){
			
		}
	}
	
	marqueeStop(){
		document.getElementById('mmm_top_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_top_scroll_mq').start();
	}
	
	getContent(item){
		try{
			var url = getLink(item.content);
			
			return <a href={url[0][1]} > {item.title} </a>;
		}
		catch(error){
			return item.title;
		}
	}
	
	render(){
		
		
		return(
			<marquee id="mmm_top_scroll_mq" scrollamount="6" onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart}  >
				<ul className="mmm-marquee-scroll" >
				{Object.keys(this.state.dataList).length > 0 ? this.state.dataList.map((item,i)=>{
					return <li key={i} >
						{this.getContent(item)}
					</li>;
				})
				: <li><i>No Current Announcements!</i></li>}
				</ul>			
			</marquee>			
		);
	}
	
}
export default TopScroll;
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import IMG_COURSE from '../../../assets/img/courses-350.png';
import IMG_FEEDBACK from '../../../assets/img/feedback-350.png';
import IMG_ERESOURCE from '../../../assets/img/resource-350.png';
import IMG_CALANDER from '../../../assets/img/calendar-350.png';
import IMG_MOU from '../../../assets/img/mou-350.png';
import IMG_FACILITY from '../../../assets/img/facility-350.png';
import IMG_SUPPORT from '../../../assets/img/support-350.png';
import IMG_COE from '../../../assets/img/coe-350.png';
import IMG_ALUMNI from '../../../assets/img/alumni-350.png';

const styles = {
	imgStyle: {
		width: '50%',
		marginTop: '10px',
	},
	txtStyle:{
		width: '100%',
		marginBottom: '-20px',
	}
}

class HomeLinks extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	componentDidMount(){

	}
	
	
	
	render(){
		
		
		return(
			<section >
				<div className="container pt-30 pb-50">
					<Row>
						<Col md={12} >
							<div className="con-title">
								<h2>Quick <span>Links</span></h2>
							</div>
						</Col>
					</Row>
					<div className="row">
						<Col md={3} className="mt-20" >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_COURSE} alt="" style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<Link to="/programmes/ug">Courses</Link>
									</h4>									
								</div>
							</div>
						</Col>
						
						<Col md={3} className="mt-20" >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_FEEDBACK} alt="" style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<Link to="/anti-ragging">Anti Harrassment</Link>
									</h4>									
								</div>
							</div>
						</Col>
						
						<Col md={3} className="mt-20" >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_ERESOURCE} alt="" style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<Link to="/e-resources">E-Resources</Link>
									</h4>									
								</div>
							</div>
						</Col>
						
						<Col md={3} className="mt-20" >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_ALUMNI} alt=""  style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<a target="_blank" href="https://alumni.sarahtuckercollege.edu.in/">Alumni</a>
									</h4>									
								</div>
							</div>
						</Col>
						
						<Col md={3} className="mt-20"  >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_MOU} alt="" style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<Link to="/mou">MOU</Link>
									</h4>									
								</div>
							</div>
						</Col>
						
						<Col md={3} className="mt-20" >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_FACILITY} alt=""  style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<Link to="/online-courses">ONLINE COURSES</Link>
									</h4>									
								</div>
							</div>
						</Col>
						
						<Col md={3} className="mt-20" >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_COE} alt="" style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<Link to="/office-of-the-coe">COE</Link>
									</h4>									
								</div>
							</div>
						</Col>
						
						<Col md={3} className="mt-20" >
							<div className="ed-rese-grid text-center">
								<div className="ed-rsear-img ed-faci-full-top">
									<img src={IMG_SUPPORT} alt="" style={styles.imgStyle} />
								</div>
								<div className="ed-rsear-dec ed-faci-full-bot " style={styles.txtStyle} >
									<h4>
										<a href="https://webadmin.sarahtuckercollege.edu.in/admin/Public/uploads/website%20images/RTI%20sarah.pdf" target="_bank" > RTI </a>
									</h4>									
								</div>
							</div>
						</Col>
						
					</div>
				</div>
			</section>
		);
	}
	
}
export default HomeLinks;

import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, getAscSortOrder} from '../../../utils';
import {CircleSlowLoader} from '../../../elements';
import axios from 'axios';

import Sidebar from './Sidebar';

class Programmes extends Component{
	constructor(props){
		super(props);
		this.state = {
			courseList: [],
			courseView: [],
			courseTypeGroup: [],
			batchList: [],
			courseType: this.props.match.params.ptype,
		}
	}
	
	componentDidMount(){
		
		this.state.courseType = this.props.match.params.ptype;
		this.loadBatchAndCourse();
	}
	
	
	componentWillReceiveProps(props){
		
		try{
			
			this.state.courseType = props.match.params.ptype;
			this.state.activeTab = '-1';
			//this.forceUpdate();
			
			this.showDataByCourseType();
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	
	loadBatchAndCourse(){
		try{
			
			this.setState({showLoader: true, batchList: [], courseList: [], courseView: []});
			axios.post(Api.AcademicPath+'course/coursewithtype/').then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.courseOrder(res['data'].data);
					this.setState({
						batchList: res['data'].batch,
						courseList: d,
						showLoader: false
					});
					
					this.showDataByCourseType();
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	courseOrder(course){
		try{
			
			var rv = [];
			
			var corder= [
				{type: 'UG', coursetype: 'regular'},
				{type: 'UG', coursetype: 'self'},
				{type: 'PG', coursetype: 'regular'},
				{type: 'PG', coursetype: 'self'},
				{type: 'PhD', coursetype: 'regular'},
				{type: 'PhD', coursetype: 'self'},
			];
			
			corder.map((item,i)=>{
				var d = course.filter(function(obj){
					return (obj.type==item.type && obj.coursetype==item.coursetype);
				});
				//rv.push(d);
				d.map((m,k)=>{
					rv.push(m);
				});
			});
			return rv;
		}
		catch(error){
			
		}
	}
	
	showDataByCourseType(){
		try{
			
			 var type = this.state.courseType;
			 
			 this.setState({courseTypeGroup: []});
			  var d = [];
			/* if(type=='mphil')
			 {
				 d = this.state.courseList.filter(function(obj){
					return(upperCase(obj.coursename)==upperCase('MPhil')); 
				 });
			 }
			 else if(type=='phd')
			 {
				 d = this.state.courseList.filter(function(obj){
					return(upperCase(obj.coursename)== upperCase('Phd')); 
				 });
			 }
			 else
			 {
				 d = this.state.courseList.filter(function(obj){
					return(upperCase(obj.type)==upperCase(type)); 
				 });
			 } */
			 if(upperCase(type)=='MPHIL' || upperCase(type)=='PHD'){
				 d = this.state.courseList.filter(item => upperCase(item.coursename)==upperCase(type));
			 }
			 else {
				 d = this.state.courseList.filter(item => 
					upperCase(item.type)==upperCase(type) && 
					(upperCase(item.coursename)!='MPHIL' && upperCase(item.coursename)!='PHD')
				);
			 }
			 
			 var s = groupByMultiple(d,function(obj){
				return[obj.coursetype]; 
			 });

			if(Object.keys(s).length>=2)
			{
				 s[0].sort(getAscSortOrder('deptname'));
				 s[1].sort(getAscSortOrder('deptname'));
			}
			else if(Object.keys(s).length>=1)
			{
				s[0].sort(getAscSortOrder('deptname'));				
			}
			
			//console.log(s);
			 this.setState({courseView: d,courseTypeGroup: s});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn pt-30">
					
					<Row>
						<Col md={9} >
						
							{this.state.showLoader ? <CircleSlowLoader />
							
							:
							<div>
								 <div className="con-title">
									<h2>{upperCase(this.state.courseType)} <span>Programmes</span></h2>
								</div>
								
								<Row>
								{this.state.courseTypeGroup.map((item,index)=>{
                                    return <Col md={6} key={index}  onClick={()=>{this.setState({activeTab: index})}} >
										<div className="shadow-hover-lg border border-2" >
                                        <div className="collapsible-header" className="collapsible-header bg-gradient-dark text-white " >
											{/*upperCase(item[0]['coursetype']=='regular' ? 'Aided' : 'Non-Aided')*/}
												
											{item[0]['coursetype']=='regular' ? 'AIDED' : 'UN-AIDED'}
												
											<div className="" style={{float:'right'}} >
												{this.state.activeTab==index ? <span className="ion-ios7-arrow-up" style={{fontSize:'20px'}} ></span>
													: <span className="ion-ios7-arrow-down" style={{fontSize:'20px'}} ></span>
												}
												</div>
											</div>
                                        <div className="collapsible-body cor-tim-tab" style={{display:'block'}} >
											<ul className="list-style-none" >
												{item.map((j,k)=>{
													var cName = j.coursename+' '+j.name+' '+j.coursetype;
													return <li key={'k__'+k} >
														{/*<Link to={"/course/"+j.id+"/"+makeUrl(cName)} >
															{j.coursename} {j.name}
														</Link>*/}
														<Link to={"/department/"+j.deptname+'/'+makeUrl(j.departmentname)} >
															{j.departmentname}
														</Link>
													</li>;
												})
												}
											</ul>
                                        </div>
										</div>
                                    </Col>;
								})
								}
                                </Row>
								
							</div>
							}
							
						</Col>
						
						<Col md={3} >
							
							<Sidebar activeMenu={DT_Programmes} activeMenuTitle="PROGRAMMES" />
							
						</Col>
					</Row>
					
				</div>
			</section>
		);
	}
	
}
export default Programmes;

import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Modal} from 'react-bootstrap';
import {Api, DT_Academics, DT_Syllabus} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import ReactModal from 'react-modal';

class ModalContent extends Component{
	constructor(props){
		super(props);
		this.state = {
			open: this.props.open,
			showModal: false,
			fileUrl: '',
		}
	}
	
	componentDidMount(){
		
	}
	
	componentWillReceiveProps(){
		
		console.log(this.props.items);
		
	}
	
	getItems(item){
		try{
			
			var d = groupByMultiple(item,function(obj){
				return[obj.filetitle];
			});
			
			return d;
		}
		catch(error){
			return [];
		}
	}
	
	
	handleLinkClick(item){
		
		this.setState({fileUrl: encodeURI(item), showModal: true});
	}
	
	render(){
		
		const modalClassName = this.props.open ? "modal fade in" : "modal fade";
		
		return(
			   <div id="modal1" className={modalClassName} role="dialog" style={{zIndex: '9'}} >
            <div className="log-in-pop py-20 px-20">
                
                
					<a href="javascript:;" className="pop-clos" onClick={this.props.atClose} style={{float:'right'}} >
						<span className="ion-close-round"></span>
                    </a>
                    <h4 className="pb-20" >PSO & Course Outcomes</h4>
                   
				   <table className="table" >
					<tbody>
					{/*{this.getItems(this.props.items).map((items,i)=>{
					var item = items[0];*/}
					{this.props.items.map((item,i)=>{
							var url = item.filepath.replace(Api.ImageUrl,'');
						 return <tr key={i} >
								<td>
								{/*<a href={Api.ImageUrl + url} target="_blank" > {item.filetitle} </a>*/}
								<a href="javascript:;" onClick={this.handleLinkClick.bind(this, Api.ImageUrl + url)} > {item.filetitle} </a>
								</td>
							</tr>;					 
					   })
					   }
				   </tbody>
				   </table>
                
            </div>
			
			
			
			<ReactModal isOpen={this.state.showModal} >
				<div className="pdf__modal__header" >
					<div className="text-left" >
						<h4>Sarah Tucker College (Autonomous) - Tirunelveli</h4>
					</div>
					<div className="text-right" style={{marginTop: '-35px'}} >
						<button type="button" onClick={(e)=>{this.setState({showModal: false, fileUrl: ''})}} className="btn btn-danger" >Close</button>
					</div>
					<hr style={{marginTop: '5px', marginBottom: '0px'}} />
				</div>
				
				<object data={this.state.fileUrl} type="application/pdf" width="100%" height="850">
					<p>
						It appears your Web browser is not configured to display PDF files. No worries, just <a href="your_file.pdf">click here to download the PDF file.</a>
					</p>
				</object>
			</ReactModal>
			
			
			
        </div>
		);
	}
	
}
export default ModalContent;

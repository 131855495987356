import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Modal} from 'react-bootstrap';
import {Api, DT_Academics, DT_Syllabus, DT_Courseoutcomes} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import axios from 'axios';
import ReactModal from 'react-modal';

import Sidebar from '../Programmes/Sidebar';
import ModalContent from './ModalContent';

class Courseoutcomes extends Component{
	constructor(props){
		super(props);
		this.state = {
			courseList: [],
			courseView: [],
			courseTypeGroup: [],
			syllabusList: [],
			syllabusView: [],
			batchList: [],
			showInnerList: false,
			innerList: [],
			activeCollapse: '0',
			courseType: this.props.match.params.ptype,
			showModal: false,
			fileUrl: '',
		}
	}
	
	componentDidMount(){
		
		this.state.courseType = this.props.match.params.ptype;
		this.loadBatchAndCourse();
		this.loadSyllabusList();
	
	}
	
	
	componentWillReceiveProps(props){
		
		try{
			
			this.state.courseType = props.match.params.ptype;
			this.state.activeTab = '-1';
			
			//this.forceUpdate();
			
			this.showDataByCourseType();
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	loadBatchAndCourse(){
		try{
			
			this.setState({showLoader: true, batchList: [], courseList: [], courseView: []});
			axios.post(Api.AcademicPath+'course/coursewithtype/').then(res=>{
				if(res['data'].status=='1')
				{
					var d = this.courseOrder(res['data'].data);
					this.setState({
						batchList: res['data'].batch,
						courseList: d,
						showLoader: false
					});
					
					this.showDataByCourseType();
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	courseOrder(course){
		try{
			this.setState({showLoader: true});
			var rv = [];
			
			var corder= [
				{type: 'UG', coursetype: 'regular'},
				{type: 'UG', coursetype: 'self'},
				{type: 'PG', coursetype: 'regular'},
				{type: 'PG', coursetype: 'self'},
			];
			
			corder.map((item,i)=>{
				var d = course.filter(function(obj){
					return (obj.type==item.type && obj.coursetype==item.coursetype);
				});
				//rv.push(d);
				d.map((m,k)=>{
					rv.push(m);
				});
			});
			this.setState({showLoader: false});
			return rv;
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	
	showDataByCourseType(){
		try{
			
			this.setState({showLoader: true});
			 var type = this.state.courseType;
			 var d = [];
			 if(type=='mphil')
			 {
				  d = this.state.courseList.filter(function(obj){
					return(upperCase(obj.coursename)==upperCase('MPhil')); 
				 });
			 }
			 else
			 {
				 d = this.state.courseList.filter(function(obj){
					return(upperCase(obj.type)==upperCase(type)); 
				 });
			 }
			 
			 var s = groupByMultiple(d,function(obj){
				return[obj.name]; 
			 });
			 //console.log(s);
			 this.setState({courseView: d,courseTypeGroup: s, showLoader: false});
			 //this.loadSyllabusByCourseType();
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	loadSyllabusList(){
		try{
			
			this.setState({showLoader: true, syllabusList: [], syllabusView: []});
			API.get('site/v1/academicfiles/courseoutcomes').then(res=>{
				if(res['data'].status=='1')
				{
					
					this.setState({
						syllabusList: res['data'].data
					});
					
					//this.loadSyllabusByCourseType();
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getSyllabusByCourse(course){
		try{
			var dt  = [];
			course.map((item,i)=>{
				var s = this.state.syllabusList.filter(function(obj){
					return(obj.courseid==item.id);
				});
				s.map((j,k)=>{
					dt.push(j);
				});
				//dt.push(s);
			});
			
			var d = dt;
			d.sort(getDescSortOrder('academicyear'));
			
			return d;
		}
		catch(error){
			
		}
	}
	
	getSyllabusCount(course){
		try{
			
			var d = this.getSyllabusByCourse(course);
			
			var m = groupByMultiple(d,function(obj){
				return[obj.academicyearid];
			});
			
			return Object.keys(m).length;
		}
		catch(error){
			return '0';
		}
	}
		
	loadSyllabusByCourse(course){
		try{

			var d = this.getSyllabusByCourse(course);
			
			// used to hide same academic year datas
			var m = groupByMultiple(d,function(obj){
				return[obj.academicyearid];
			});
			// used to hide same academic year datas
			/*<a href={Api.ImageUrl+url} target="_blank" >
					<h4 style={{paddingBottom:'4px'}} >{item[0].academicyear}</h4>
					<div className="pb-10" >{item.length} file(s)</div>
				</a>*/
			//console.log(d);
			let sno = 1;
			var rv = [];
			m.map((item,i)=>{
				//var url = item[0].filepath.replace(Api.ImageUrl,'');
				
				rv.push(
				<Col md={3} ><div className="mmm-syllabus-data-container shadow-hover-lg mb-15" onClick={this.openShowListModal.bind(this,item)} >
				
				<h4 style={{paddingBottom:'4px'}} >
					{item[0].academicyear}
				</h4>
				<div className="pb-10" >{item.length} file(s)</div>
			</div>
			</Col>);
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	
	openShowListModal(item){
		try{
			
			this.setState({innerList: item, showInnerList: true});
		}
		catch(error){
			
		}
	}
	
	
	handleLinkClick(item){
		
		this.setState({fileUrl: encodeURI(item), showModal: true});
	}
	
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn pt-30">
					
					<Row>
						<Col md={8} >
						
							{this.state.showLoader ? <CircleSlowLoader />
							
							:
							<div>
								 <div className="con-title">
									<h2>{upperCase(this.state.courseType)} PO, PSO <span>& Course Outcomes</span></h2>
								</div>
								
								<ul className="collapsible list-style-none" data-collapsible="accordion">
								{this.state.courseTypeGroup.map((item,index)=>{
                                    return <li key={index} className={this.state.activeTab==index ? 'active' : ''} onClick={()=>{this.setState({activeTab: index})}} >
                                        <div className={this.state.activeTab==index ? "collapsible-header bg-gradient-dark text-white " : "collapsible-header "} >{upperCase(item[0]['name'])}
											{/*&nbsp;&nbsp;- {this.getSyllabusCount(item)} file(s)*/}
												<div className="" style={{float:'right'}} >
												{this.state.activeTab==index ? <span className="ion-ios7-arrow-up text-white" style={{fontSize:'20px'}} ></span>
													: <span className="ion-ios7-arrow-down" style={{fontSize:'20px'}} ></span>
												}
												</div>
										</div>
                                        <div className="collapsible-body cor-tim-tab" style={{display: this.state.activeTab==index ? 'block' : 'none'}} >
											{/*item.map((j,k)=>{
												return <Row>{this.loadSyllabusByCourse(j)}</Row>;
											})
											*/}
											<Row>{this.loadSyllabusByCourse(item)}</Row>
                                        </div>
                                    </li>;
								})
								}
                                </ul>
								
							</div>
							}
							
						</Col>
						
						<Col md={4} >
							
							<Sidebar activeMenu={DT_Courseoutcomes} activeMenuTitle="COURSE OUTCOMES" />
							
						</Col>
					</Row>
					
				</div>
				
				<ModalContent open={this.state.showInnerList} atClose={()=>{this.setState({showInnerList: false})}} items={this.state.innerList} />
				
			</section>
		);
	}
	
}
export default Courseoutcomes;

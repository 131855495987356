import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import ReactModal from 'react-modal';

class FirstTimeModal extends Component{
	constructor(props){
		super(props);
		this.state = {
			showModal: false
		}
	}
	
	render(){
		
		
		return(
			<div className="my_modal_announcement" >
				<div id="modal3" className={this.state.showModal ? "modal fade in" : "modal fade"} role="dialog">
					<div className="log-in-pop modal-popup-content">
						<div className="">
							<a href="javascript:;" className="modal-close" data-dismiss="modal" onClick={(e)=>{this.setState({showModal: false})}} >
								<i className="ion-ios7-close-empty" />
							</a>
							<h4>Forgot password</h4>
							<p>Don't have an account? Create your account. It's take less then a minutes</p>
							<form className="s12">
								<div>
									<div className="input-field s12">
										<input type="text" data-ng-model="name3" className="validate" />
										<label>User name or email id</label>
									</div>
								</div>
								<div>
									<div className="input-field s4">
										<input type="submit" value="Submit" className="waves-effect waves-light log-in-btn" /> </div>
								</div>
								<div>
									<div className="input-field s12"> <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#modal1">Are you a already member ? Login</a> | <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#modal2">Create a new account</a> </div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
}
export default FirstTimeModal;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {CircleLoader} from '../../../elements';

import SliderItem from './SliderItem';

class Slider extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			dataList: [],
			activeSlide: 0
		}
	}
	
	componentDidMount(){
		
		this.loadSliders();
	}
	
	loadSliders()
	{
		try{
			this.setState({showLoader: true});
			API.get('site/v1/sliders/').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
		const Items = [
			{
				src: 'http://sarahtuckercollege.edu.in/web/wp-content/uploads/2017/12/bg-5.jpg',
				caption: 'Slider I',
				header: '',
				altText: '',
				key: '1'
			},
			{
				src: 'http://sarahtuckercollege.edu.in/web/wp-content/uploads/2018/04/bg-6.jpg',
				caption: 'Slider II',
				header: '',
				altText: '',
				key: '2'
			},
			{
				src: 'http://sarahtuckercollege.edu.in/web/wp-content/uploads/2017/12/bg33.jpg',
				caption: 'Slider III',
				header: '',
				altText: '',
				key: '3'
			}
		];
		
		return(
			<section >
			<div id="myCarousel" className="carousel slide" data-ride="carousel">
			
				{this.state.showLoader ? 
					<div style={{minHeight: '400px', textAlign:'center', paddingTop: '10%' }} >
						<center><CircleLoader /></center>
					</div>
					:				
					<div className="carousel-inner">
						
						{this.state.dataList.map((item,i)=>{
							return <SliderItem item={item} index={i} url={Api.ImageUrl} />
						})
						}
						
					</div>
					
				}
					<a className="left carousel-control" href="#myCarousel" data-slide="prev">
						<i className="ion-chevron-left slider-arr"></i>
					</a>
					<a className="right carousel-control" href="#myCarousel" data-slide="next">
						<i className="ion-chevron-right slider-arr"></i>
					</a>
					
					
			</div>
			
			</section>
		);
	}
	
}
export default Slider;
import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl} from '../../../utils';

import LOGO from '../../../assets/img/stc-logo.png';
import admissionGif from '../../../assets/img/admission.gif';
import TopHeader from './TopHeader';
import TopMenu from './TopMenu';
import TopScroll from '../TopScroll';

const styles = {
	logoImg: {
		width: '100%',
		padding: '8px 8px 8px 8px',
	},
	headerTop: {
		borderTop: '4px solid #FAB0D5',
		background: 'rgb(255, 219, 237)',
		/*borderBottom: '1px solid #f6f7f6',*/
	}
}

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			categoryList: [],
		}
	}
	
	componentDidMount(){
		window.addEventListener('scroll', this.handleWindowScroll);
		//window.addEventListener('click', this.globalClick);
	}
	
	handleWindowScroll(){
		
		//var header = document.getElementById("top-header");
		var header = document.getElementsByClassName("app-page-content")[0];
		// Get the offset position of the navbar
		var sticky = header.offsetTop;
		
		if (window.pageYOffset > sticky) {
			header.classList.add("fixed-top");
		  } else {
			header.classList.remove("fixed-top");
		  }
	}
	
	marqueeStop(){
		document.getElementById('mmm_top_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_top_scroll_mq').start();
	}
	
	render(){
		
		const cookie = new Cookies();
		let LogoImage = LOGO;
		
		let logo = getCookieField('site','logo');
		if(logo!='' && logo!=undefined)
			LogoImage = Api.ImageUrl + logo;
		
		
		$('html, body').animate({scrollTop:0}, 'slow');
		
		return(
			<section style={styles.headerTop} >
			
				<TopHeader />
				
				<div className="container mob-hide"  >
					<div className="row" >
						<div className="col-md-3" >
							<div style={{marginTop: '20px',}} >
								<i className="ion-ios7-telephone" style={{fontSize: '40px', float: 'left', marginRight: '10px' }}  />
								{getCookieField('site','phone')} <br />
								{getCookieField('site','email')}
							</div>
						</div>
						<div className="col-md-6 text-center" >
							<img src={LOGO} style={styles.logoImg}  />
						</div>
						<div className="col-md-3" >
							<div style={{marginTop: '20px',}} >
								<i className="ion-ios7-location" style={{fontSize: '40px', float: 'left', marginRight: '10px' }}  />
								{getCookieField('site','address')},{getCookieField('site','city')}<br />
								{getCookieField('site','state')} - {getCookieField('site','pincode')}
							</div>
						</div>
					</div>
				</div>
				
				{/*<div className="top-admission-gif" >
					<a href="https://admission.sarahtuckercollege.edu.in" >
						<img src={admissionGif}/>
					</a>
		</div>*/}
				
				<TopMenu logo={LOGO}  />
				
			</section>
		);
	}
	
}
export default Header;
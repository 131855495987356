import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';

class SliderItem extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	render(){
		
		var item = this.props.item;
		return(
			<div className={this.props.index=='0' ? 'item slider1 active' : 'item'} >
				<img src={this.props.url + item.url} alt="" /> 
				<div className="carousel-caption slider-con">
				{item.title.length > 0 ? <h2>{item.title}</h2> : null}
				   {item.content.length > 0 ?  <p>{item.content}</p> : null}
					   {/**<a href="#" className="bann-btn-1">All Courses</a><a href="#" className="bann-btn-2">Read More</a> **/}
				</div>
			</div>
		);
	}
	
}
export default SliderItem;
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/ionicons/css/ionicons.min.css';
import './assets/css/style.css';
import './assets/css/list-style.css';
import './index.css';


import App from './App';

import './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));


import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import ReactModal from 'react-modal';

class TeachingPlan extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: this.props.files,
			dataGroup: [],
			activeTab: '0',
			activeInnerId: '',
			showModal: false,
			fileUrl: '',
		}
	}
	
	componentDidMount(){
		
		this.groupFiles();
	}
	
	
	groupFiles(){
		try{
			
			var data = this.props.files;
			data.sort(getDescSortOrder('forcourse'));
			var d = groupByMultiple(data, function(obj){
				return[obj.forcourse];
			});
			
			this.setState({dataGroup: d});
		}
		catch(error){
			
		}
	}

	loadSyllabusByCourse(course){
		try{

			
			course.sort(getDescSortOrder('academicyear'));
			
			// used to hide same academic year datas
			var m = groupByMultiple(course,function(obj){
				return[obj.academicyearid];
			});
			
			// used to hide same academic year datas
			//console.log(d);
			var rv = [];
			m.map((item,index)=>{
				
				var url = item[0].filepath.replace(Api.ImageUrl,'');
				var i_id = 'forcourse_'+index;
			rv.push(<div className="shadow-hover-lg" onClick={()=>{this.setState({activeInnerId: i_id})}} >
							<div className={this.state.activeInnerId==i_id ? "collapsible-header bg-gradient-dark text-white" : "collapsible-header"} >
								{item[0].academicyear}					
								<div className="" style={{float:'right'}} >
								{this.state.activeInnerId==i_id ? <span className="ion-ios7-arrow-up text-white" style={{fontSize:'20px'}} ></span>
									: <span className="ion-ios7-arrow-down" style={{fontSize:'20px'}} ></span>
								}
								</div>
							</div>
							<div className="collapsible-body cor-tim-tab" style={{display: this.state.activeInnerId==i_id ? 'block' : 'none'}} >
								<ul className="list-style-none" >
								{item.map((j,k)=>{
									return <li key={k} >
										{/*<a href={Api.ImageUrl + j.filepath.replace(Api.ImageUrl,'')} target="_blank" >*/}
										<a href="javascript:;" onClick={this.handleLinkClick.bind(this, Api.ImageUrl + j.filepath.replace(Api.ImageUrl,''))} >
											{j.filetitle}
										</a>
									</li>;
								})
								}
								</ul>
							</div>
					</div>);
			
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	
	handleLinkClick(item){
		
		this.setState({fileUrl: encodeURI(item), showModal: true});
	}
	
	render(){
		
		
		return(
			 <div>
				
				<ul className="nav nav-tabs" >
				{this.state.dataGroup.map((item,index)=>{
					
					return <li key={index}  onClick={()=>{this.setState({activeTab: index})}} className={this.state.activeTab==index ? 'active' : ''} >
						
								<a data-toggle="tab" >{upperCase(item[0].forcourse)}</a>
					</li>;
				})
				}
				</ul>
				
				<div className="tab-content">
				{this.state.dataGroup.map((item,index)=>{
					
					return <div id={index} key={index} className={this.state.activeTab==index ? 'tab-pane fade in active' : 'tab-pane fade in'} >
						
								{this.loadSyllabusByCourse(item)}
					</div>;
				})
				}
				</div>
				
				
				<ReactModal isOpen={this.state.showModal} >
					<div className="pdf__modal__header" >
						<div className="text-left" >
							<h4>Sarah Tucker College (Autonomous) - Tirunelveli</h4>
						</div>
						<div className="text-right" style={{marginTop: '-35px'}} >
							<button type="button" onClick={(e)=>{this.setState({showModal: false, fileUrl: ''})}} className="btn btn-danger" >Close</button>
						</div>
						<hr style={{marginTop: '5px', marginBottom: '0px'}} />
					</div>
					
					<object data={this.state.fileUrl} type="application/pdf" width="100%" height="850">
						<p>
							It appears your Web browser is not configured to display PDF files. No worries, just <a href="your_file.pdf">click here to download the PDF file.</a>
						</p>
					</object>
				</ReactModal>
				
			 </div>
		);
	}
	
}
export default TeachingPlan;

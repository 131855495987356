import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import IMG_VISION from '../../../assets/img/vision-650.png';
import IMG_MISSION from '../../../assets/img/mission-650.png';

class Vision extends Component{
	constructor(props){
		super(props);
		this.state = {
			activeTab: 'vision',
		}
	}
	
	componentDidMount(){

	}
	
	
	
	render(){
		
		
		return(
			<section className="bg-light" >
				<div className="container pt-30 pb-30 " >
					
					<Row>
					
						<Col md={6} >
							<div className="text-center">
								<h3>About College</h3>
							</div>
							<div style={{lineHeight: '26px', textAlign:'justify'}} >
								&emsp; Sarah Tucker College is the 1st rural college for women in Palayamkottai, a town in Tirunelveli District in the southern state of TN (Tamil Nadu) in India. It was established in the year 1895 by the Christian Mission Society. The institutions started with only 4 students. It is affiliated with the Madras University as II grade college. After Isabella Thoburn College, It is the 2nd oldest college in India. The college offers 10 UG, 6 PG, 2 M.Phil and Six Doctoral programs in various specializations including English, Tamil, Economics, History, Mathematics, Physics, Chemistry, Botany and Zoology. It also provides some short-term certificate programs in the area of Computer Applications, PC Maintenance and Trouble Shooting, Tailoring, Hand Embroidery, Communicative English, Spoken Hindi, Journalism and Cosmetology and Fashion Designing. The institution is working under the TDTA (Tirunelveli Diocesan Association) and accredited with “A” Grade by the National Assessment and Accreditation Council (NAAC) of India. Currently, thirty-three students are studying at Sarah Tucker College. The motto of the college is “So Run that ye may obtain the incorruptible Crown”. The college has a library, laboratories, Computer centre and auditorium. It also has 5 hostels, which accommodate around six-hundred students.
							</div>							
						</Col>
						
						<Col md={6} >
							
							 <ul className="nav nav-tabs">
								<li className={this.state.activeTab=='vision' ? 'active' : ''} >
									<a onClick={()=>{this.setState({activeTab: 'vision'})}} >
										<span>Vision</span>
									</a>
								</li>
								<li className={this.state.activeTab=='mission' ? 'active' : ''} >
									<a onClick={()=>{this.setState({activeTab: 'mission'})}} >
										<span>Mission</span>
									</a>
								</li>
							</ul>
							
							<div className="tab-content">
								<div className={this.state.activeTab=='vision' ? "tab-pane fade in active" : "tab-pane fade in"} >
									
									<div className="ed-rese-grid ed-rese-grid-mar-bot-30">
										<div className="ed-rsear-img ed-faci-full-top">
											<img src={IMG_VISION} alt="" />
										</div>
										<div className="ed-rsear-dec ed-faci-full-bot" style={{minHeight: '190px' }} >
											<h4><a href="facilities-detail.html">Vision Statement</a></h4>
											<p className="font-14" >To be an institute of excellence where young girls can achieve full potential in their academic, creative, physical and spiritual development so as to become empowered and worthy citizens capable of nuturing the society and the nation.</p>
										</div>
									</div>
									
									
								</div>
								<div className={this.state.activeTab=='mission' ? "tab-pane fade in active" : "tab-pane fade in"} >
									
									<div className="ed-rese-grid ed-rese-grid-mar-bot-30">
										<div className="ed-rsear-img ed-faci-full-top">
											<img src={IMG_MISSION} alt="" />
										</div>
										<div className="ed-rsear-dec ed-faci-full-bot">
											<h4><a href="facilities-detail.html">Mission  Statement</a></h4>
											<p className="font-14" >Service through Knowledge.</p>
											<h4><a href="facilities-detail.html">Motto</a></h4>
											<p className="font-14" >“So run that ye may obtain the incorruptible crown”</p>
										</div>
										
									</div>
									
								</div>
							</div>
							
						</Col>
						
					</Row>
					
				</div>
			</section>
		);
	}
	
}
export default Vision;

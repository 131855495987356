import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import './index.css';

const styles = {
	modalContent: {
	    height: 'calc(95vh)',
		margin: '15px',
		maxWidth: '1000px',
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: '20px',
	},
	closeButton: {
		fontSize: '20px',
		float: 'right',
		cursor: 'pointer',
	},
	titleText: {
		borderBottom: '1px solid #e6e5e5',
		paddingBottom: '10px',
	}
}

class ModalPdfViewer extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	getTitle(){
		try{
			var d = this.props.data;
			return d['category'];
		}
		catch(error){ }
	}
	
	getFileUrl(){
		try{
			var d = this.props.data;
			return d['fileurl'];
		}
		catch(error){ }
	}
	
	render(){
		
		var url = this.getFileUrl();
		
		/*return(
			<Modal show={this.props.show} className="modal-pdffile-viewer" onHide={this.props.hide} >
					<Modal.Header closeButton >
						<Modal.Title>{this.getTitle()}</Modal.Title>
					</Modal.Header>
				 <Modal.Body>
					
					{url!=undefined && url.length > 4 ? <div>
						<iframe src={url} style={{width: '100%', height: 'calc(100vh)', border: '0px'}} ></iframe>
					</div> : <h3>Sorry file not found..!</h3>}
					
				</Modal.Body>
			</Modal>
		);*/
		
		return(
			<div id="" className="modal fade in modal-fullpage" role="dialog" style={{display: 'block'}}>
            <div className="log-in-pops">
                <div className="bg-white" style={styles.modalContent} >
					<div style={styles.closeButton} >X</div>
					<div style={styles.titleText} >File Title</div>
					<div>
						
						
			
					</div>
				</div>
            </div>
        </div>
		);
	}
	
}
export default ModalPdfViewer;

import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Syllabus} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import moment from 'moment';

import Sidebar from './Sidebar';
import Events from '../../elements/NewsScroll/Events';
import EVENT_BG from '../../../assets/img/event-bg.jpg';

const styles = {
	headerStyle: {
		marginLeft: '-22px',
		marginTop: '-30px',
		marginRight: '-24px',
		background: `url(${EVENT_BG})`
	}
}

class SingleEvent extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showNewsLoader: false,
			upcomingList: [],
			dataList: [],
			slug: this.props.match.params.slug,
		}
	}
	
	componentDidMount(){
		this.loadNews();
		this.loadUpcomingNews();
	}
	
	loadNews(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/news/'+this.state.slug).then(res=>{
				if(res['data'].status=='1'){ 
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadUpcomingNews(){
		try{
			this.setState({showNewsLoader: true});
			var data = new FormData();
			data.append('type','upcoming');
			data.append('limit', '10');
			
			API.post('site/v1/news/list',data).then(res=>{
				console.log(res);
				if(res['data'].status=='1') {
					this.setState({upcomingList: res['data'].data, showNewsLoader: false});
				}
				else {
					this.setState({showNewsLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showNewsLoader: false});
		}
	}
	
	componentWillReceiveProps(props){
		this.state.slug = props.match.params.slug;
		this.loadNews();
	}
	
	getField(fieldName){
		try{
			var d = this.state.dataList;
			return d[fieldName];
		}
		catch(error){ }
	}
	 
	getDate(value){
		try{
			return moment(value).format('DD,MMM YYYY');
		}
		catch(error){ }
	}
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn  pt-30 pb-50 ">
					<Row>
						<Col md={12} >
							<div className="head-2 mb-15" style={styles.headerStyle} >
								<div className="container">
									<div className="head-2-inn">
										<h1>{this.getField('title')}</h1>
										<div className="event-head-sub">
											<ul style={{listStyle: 'none'}} >
											{this.getField('eventstartdate')!='' ? <li>Date: {this.getDate(this.getField('eventstartdate'))} </li> : null}
											{this.getField('eventenddate')!='' ? <li>To: {this.getDate(this.getField('eventenddate'))} </li> : null}	
											{this.getField('eventvenue')!='' ? <li>Location: {this.getField('eventvenue')} </li> : null}	
											</ul>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col md={8} >							
							{this.state.showLoader ? <CircleSlowLoader />
								:<div>
									<div dangerouslySetInnerHTML={{__html: this.getField('content')}} />
								</div>
							}							
						</Col>
						<Col md={4} >
							{this.state.showNewsLoader ?
								<div className="bot-gal h-blog ho-event" ><h4>Upcoming Events</h4><CircleSlowLoader /></div> : 
								<Events data={this.state.upcomingList} />
							}							
						</Col>
					</Row>
				</div>				
			</section>
		);
	}
	
}
export default SingleEvent;

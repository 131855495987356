import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Tabs, Tab} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

class MarqueeAnnouncement extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
		}
	}
	
	componentDidMount(){
		this.loadAnnounceMents();
	}
	
	loadAnnounceMents(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('type','announcement');
			form.append('expired','1');
			API.post('site/v1/news/list',form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	marqueeStop(){
		document.getElementById('mmm_an_scroll_mq').stop();
	}
	
	marqueeStart(){
		
		document.getElementById('mmm_an_scroll_mq').start();
	}
	
	render(){
		
		
		return(
			<div >
				<div style={{fontSize: '15px'}} >
					<marquee id="mmm_an_scroll_mq" direction="up" scrollamount="4" style={{height: '200px'}} onMouseOver={this.marqueeStop} onMouseLeave={this.marqueeStart}  >
						<ul className="list-style-none" >
						{Object.keys(this.state.dataList).length > 0 ? this.state.dataList.map((item,i)=>{
							return <li key={i} >
								{/*item.title
								<br />*/}
								<div dangerouslySetInnerHTML={{__html: item.content}} />
							</li>;
						})
						: <li><i>No Current Announcements!</i></li>}
						</ul>
					</marquee>
				</div>
			</div>
		);
	}
	
}
export default MarqueeAnnouncement;

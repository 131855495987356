import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import ReactModal from 'react-modal';

class Programoutcomes extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: this.props.files,
			dataGroup: [],
			activeTab: '-1',
			showModal: false,
			fileUrl: '',
		}
	}
	
	componentDidMount(){
		
		this.groupFiles();
	}
	
	
	groupFiles(){
		try{
			
			var data = this.props.files;
			data.sort(getDescSortOrder('forcourse'));
			var d = groupByMultiple(data, function(obj){
				return[obj.forcourse];
			});
			
			this.setState({dataGroup: d});
		}
		catch(error){
			
		}
	}

	loadSyllabusByCourse(course){
		try{

			
			course.sort(getDescSortOrder('academicyear'));
			
			// used to hide same academic year datas
			/*var m = groupByMultiple(course,function(obj){
				return[obj.academicyearid];
			});*/
			
			// used to hide same academic year datas
			//console.log(d);
			var rv = [];
			course.map((item,i)=>{
				var url = item.filepath.replace(Api.ImageUrl,'');
			rv.push(<Col md={4} ><div className="mmm-syllabus-data-container shadow-hover-lg mb-15" >
				{/*<a href={Api.ImageUrl+url} target="_blank" >*/}
				<a href="javascript:;" onClick={this.handleLinkClick.bind(this,Api.ImageUrl+url)} >
					<h4 className="pb-0" >{item.academicyear}</h4>
					<div className="pb-10" >{item.filetitle}</div>
				</a>
			</div></Col>);
			
			});
			
			return rv;
		}
		catch(error){
			
		}
	}
	
	handleLinkClick(item){
		
		this.setState({fileUrl: encodeURI(item), showModal: true});
	}
	
	render(){
		
		
		return(
			 <div>
				
				<Row>
				{this.state.dataGroup.map((item,index)=>{
					
					return <Col md={12} key={index}  onClick={()=>{this.setState({activeTab: index})}} >
						<div className="shadow-hover-lg" >
							<div className={this.state.activeTab==index ? "collapsible-header bg-gradient-dark text-white" : "collapsible-header"} >
							{/*upperCase(item[0]['coursetype']=='regular' ? 'Aided' : 'Non-Aided')*/}
								
								{upperCase(item[0].forcourse)}
								
							<div className="" style={{float:'right'}} >
								{this.state.activeTab==index ? <span className="ion-ios7-arrow-up" style={{fontSize:'20px'}} ></span>
									: <span className="ion-ios7-arrow-down" style={{fontSize:'20px'}} ></span>
								}
								</div>
							</div>
						<div className="collapsible-body cor-tim-tab" style={{display: this.state.activeTab==index ? 'block' : 'none'}} >
							<Row>
							{this.loadSyllabusByCourse(item)}
							</Row>
						</div>
						</div>
					</Col>;
				})
				}
				</Row>
				
				
				<ReactModal isOpen={this.state.showModal} >
					<div className="pdf__modal__header" >
						<div className="text-left" >
							<h4>Sarah Tucker College (Autonomous) - Tirunelveli</h4>
						</div>
						<div className="text-right" style={{marginTop: '-35px'}} >
							<button type="button" onClick={(e)=>{this.setState({showModal: false, fileUrl: ''})}} className="btn btn-danger" >Close</button>
						</div>
						<hr style={{marginTop: '5px', marginBottom: '0px'}} />
					</div>
					
					<object data={this.state.fileUrl} type="application/pdf" width="100%" height="850">
						<p>
							It appears your Web browser is not configured to display PDF files. No worries, just <a href="your_file.pdf">click here to download the PDF file.</a>
						</p>
					</object>
				</ReactModal>
				
				
			 </div>
		);
	}
	
}
export default Programoutcomes;

import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes, DT_CourseMenu} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, capitalizeFirst, getAscSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';
import axios from 'axios';
import ReactModal from 'react-modal';

import Sidebar from './Sidebar';
import Syllabus from './Syllabus';
import Programoutcomes from './Programoutcomes';
import TeachingPlan from './TeachingPlan';
import Faculty from './Faculty';
import DeptSlider from './DeptSlider';

const styles = {
	deptDropdwon: {
		background: '#03294e',
		border: '0px',
		color: '#fff',
	}
}

class Department extends Component{
	constructor(props){
		super(props);
		this.state = {
			courseList: [],
			employeeList: [],
			departmentEmployees: [],
			courseView: [],
			courseTypeGroup: [],
			batchList: [],
			currentCourse: [],
			materialList: [],
			actieTab: 'about',
			courseId: this.props.match.params.cid,
			pageData: [],
			facultyList: [],
			filesList: [],
			deptList: [],
			showModal: false,
			fileUrl: '',
		}
		
	}
	
	componentDidMount(){
		
		var hash = window.location.hash;
		
		if(hash!=undefined && hash != null && hash.length>0){
			var slug = hash.replace('#','');
			
			this.setState({actieTab: slug});
			
			if(slug=='ematerial' || slug=='faculty'){
				this.loadMaterialList();
				this.loadEmployeeInfo();
			}
		}
		this.loadDepartments();
		this.loadDepartmentInfo();
		this.loadPageData();
		
		//this.loadCourseInfo();
		//this.loadPageData();
	}
	
	loadDepartments(){
		try{
			
			this.setState({showLoader: true});
			axios.get(Api.AcademicPath+'course/departments').then(res=>{
				if(res['data'].status=='1'){ 
					this.setState({deptList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	

	componentWillReceiveProps(props){
		
		try{
			
			//this.setState({courseList: [], courseView: [],  batchList: [],  facultyList: [] });
						
			this.state.courseId = props.match.params.cid;
			this.setState({courseId: props.match.params.cid});
			//this.state.activeTab = 'about';
			//this.loadDepartmentInfo();
			//this.loadPageData();
			var hash = window.location.hash;
			if(hash!=undefined && hash != null && hash.length>0){
				var slug = hash.replace('#','');
				
				this.setState({actieTab: slug});
				
				if(slug=='ematerial' || slug=='faculty'){
					this.loadMaterialList();
					this.loadEmployeeInfo();
				}
			}
			else{
				this.setState({actieTab: 'about'});
			}
			//this.loadCourseInfo();
			this.loadDepartmentInfo();
			this.loadPageData();
		}
		catch(error){
			console.log(error);
		}
	}
	
	loadDepartmentInfo(){
		try{
			/*if(Object.keys(this.state.courseList).length>0){
				return;
			}*/
			this.setState({showLoader: true});
			//var courseId = this.props.match.params.cid;
			axios.get(Api.AcademicPath+'course/coursebydept/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({
						courseList: res['data'].data,
						courseView: res['data'].data,
						batchList: res['data'].batch,
						facultyList: res['data'].employees,
						showLoader: false});
				}
				else
				{
					this.setState({showLoader: false, currentCourse: []});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadCourseInfo(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.AcademicPath+'course/getdata/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({showLoader: false, currentCourse: res['data'].data});
				}
				else
				{
					this.setState({showLoader: false, currentCourse: []});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadPageData(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/department/page/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].pagedata, filesList: res['data'].filelist, showLoader: false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadMaterialList(){
		try{
			
			this.setState({showLoader: true, materialList: []});
			API.get('site/v1/eresource/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					var d = groupByMultiple(res['data'].data,function(obj){
						return[obj.staffid];
					});
					this.setState({
						materialList: d,
						showLoader: false
					});
					
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}

	loadEmployeeInfo(){
		try{
			
			this.setState({showLoader: true, employeeList: [], departmentEmployees: []});
			axios.post(Api.AcademicPath+'course/coursebydept/'+this.state.courseId).then(res=>{
				if(res['data'].status=='1')
				{
					var empList = [];
					var emp = res['data'].employees;
					var aidedEmp = [];
					var nonEmp = [];
					var mgT = [];
					if(Object.keys(emp).length>0){
						aidedEmp = emp.filter(function(obj){
							return(obj.emptype=='aided');
						});
						nonEmp = emp.filter(function(obj){
							return(obj.emptype=='non-aided' || obj.emptype=='mca');
						});
						mgT = emp.filter(function(obj){
							return(obj.emptype=='management');
						});
						
						/* var mca = emp.filter(function(obj){
							return(obj.emptype=='mca');
						});
						if(Object.keys(mca).length>0){
							mca.map((j,k)=>{
								nonEmp.push(j);
							});
						} */
					}
					
					//aidedEmp.sort(getAscSortOrder('displayorder'));
					//nonEmp.sort(getAscSortOrder('displayorder'));
										
					empList['aided'] = aidedEmp;
					empList['unaided'] = nonEmp;
					empList['management'] = mgT;
					
					
					this.setState({
						employeeList: empList,
						departmentEmployees: emp,
						showLoader: false
					});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getStaffName(id){
		try{
			var d = this.state.departmentEmployees.filter(function(obj){
				return(obj.id==id);
			});
			
			return Object.keys(d).length>0 ? d[0].empname+' '+d[0].qualification : '';
		}
		catch(error){
			return '';
		}
	}
	
	listMaterialsByStaff(item){
		try{
			var rv = [];
			var d = item;/* this.state.dataList.filter(function(obj){
				return(obj.staffid==id);
			});
			*/
			d.map((item,i)=>{
				console.log(item);
				rv.push(<li key={i} style={{marginBottom: '5px', fontSize: '17px'}} >
					{item.linktype=='pdf' ? 
					<a href="javascript:;" onClick={this.handleLinkClick.bind(this,Api.ImageUrl + item.filepath)} >
						{item.filetitle}
					</a>
					: <a href={Api.ImageUrl + item.filepath} target="_blank" >
						{item.filetitle}
					</a>
					}
				</li>);
			});
			
			return rv;
		}
		catch(error){
			return '';
		}
	}
	
	
	handleLinkClick(item){
		
		this.setState({fileUrl: encodeURI(item), showModal: true});
	}
	
	displayPageData(){
		try{
			
			//console.log(this.state.pageData);
			var actieTab = this.state.actieTab;
			var d = this.state.pageData.filter(function(obj){
				return(upperCase(obj.category)==upperCase(actieTab));
			});
			
			if(Object.keys(d).length>0)
			{
				return d[0]['pagecontent'];
			}
			else
				return '';
		}
		catch(error){
			return '';
		}
	}
	
	getFilesByCategory(category){
		try{
			
			var d = this.state.filesList.filter(function(obj){
				return(upperCase(obj.category)==upperCase(category));
			});
			
			return d;
		}
		catch(error){
			return '';
		}
	}
	
	getCourseFieldValue(fieldName){
		try{
			
			var d = this.state.courseList;
			return d[0][fieldName];
		}
		catch(error){
			
		}
	}
	
	
	render(){
		
		/*if(this.state.isRedirect) {
			return <Redirect to={this.state.redirectUrl} />;
		}*/
		
		return(
			  <section>
				<div className="container pg-inn pt-30 dept-page">
					
					<Row>
					<Col md={3} >
						{/*this.state.showLoader ? <CircleSlowLoader /> : */}
							<Row>
								<Col md={12} >
									<div className="de-left-tit" >
									<h4><span className="ion-ios7-arrow-right" style={{
												color:'#fff',
												fontSize: '20px',
												fontWeight: '600',
												marginRight: '5px',
									}} ></span> MENU</h4> 
									</div>
									<div className="ho-event">
										<ul className="mmm-sidebar-menu" >
										{DT_CourseMenu.map((item,i)=>{
											return <li key={i} >
												<div className="ho-ev-link ho-ev-link-full">
													<a href={"#"+item.link}  onClick={()=>{this.setState({actieTab: item.link})}}
													className={this.state.actieTab==item.link ? 'text-danger' : ''} >
														{item.name}
													</a>
												</div>
											</li>;
										})
										}
										</ul>
									</div>	
								</Col>
							</Row>
							
						</Col>

						<Col md={6} >
							{/*<Row>
								<Col md={12} >
									

									<ul className="nav nav-tabs" >
										{DT_CourseMenu.map((item,i)=>{
											return <li key={i}  >
													<a data-toggle="tab" href={"#"+item.link}  onClick={()=>{this.setState({actieTab: item.link})}}
													className={this.state.actieTab==item.link ? ' text-danger' : ' '} >
														{item.name}
													</a>
											</li>;
										})
										}
										</ul>
								</Col>
							</Row>	*/}
							{this.state.showLoader ? <CircleSlowLoader />
							
							:
							<div>
								
								<div style={{textAlign:'center', borderBottom: '1px solid #9e9e9e', marginBottom: '0px' }} className="con-title" > 
								<h2 style={{fontSize:'22px'}} >Department of <span style={{fontSize:'22px'}} >{upperCase(this.getCourseFieldValue('departmentname'))}</span></h2>
								</div>
							
							<div style={{width:'100%'}} >
								
								{this.state.actieTab=='about' ?  <DeptSlider deptId={this.state.courseId} /> : null}
								
								{this.state.actieTab=='about' ? 
									<div>
										<h4 className="my-15" >About</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='courses' ? 
									<div>
										<h4 className="my-15" >Courses Offered</h4>
										<div>
											<table className="table table-bordered">
												<thead style={{background: '#f1f1f1'}} >
													<tr>
														<th>Programme  Name</th>
														<th>Programme  Type</th>
													</tr>
												</thead>
												<tbody>
												{this.state.courseView.map((item,i)=>{
													return <tr key={i} >
														<td><b>{item.coursename} - {item.name}</b></td>
														<td>{item.coursetype=='self' ? 'Un-Aided' : 'Aided'}</td>
													</tr>;
												})
												}
												</tbody>
											</table>
										</div>
								</div> : null}
								
								{this.state.actieTab=='vision' ? 
									<div>
										<h4 className="my-15" >Vision & Mission</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='faculty' ? 
									<div>
										
										{Object.keys(this.state.employeeList).length>0 ?
										<div>		
										{Object.keys(this.state.employeeList.aided).length > 0 ?
											<div>
												<h4 className="my-15" >Aided Faculty</h4>
												<Faculty  facultyList={this.state.employeeList.aided} />
											</div>
										: null}
										{Object.keys(this.state.employeeList.unaided).length > 0 ?
											<div>
												<h4 className="my-15" >Un-Aided Faculty</h4>
												<Faculty  facultyList={this.state.employeeList.unaided} />
											</div>
										:null}
										{Object.keys(this.state.employeeList.management).length > 0 ?
											<div>
												<h4 className="my-15" >Management Faculty</h4>
												<Faculty  facultyList={this.state.employeeList.management} />
											</div>
										:null}
										</div>
										: null}
								</div> : null}
								
								{this.state.actieTab=='syllabus' ? 
									<div>
										<h4 className="my-15" >Syllabus</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
										<hr />
										<Syllabus files={this.getFilesByCategory('syllabus')} />
								</div> : null}
								
								
								{this.state.actieTab=='courseoutcomes' ? 
									<div>
										<h4 className="my-15" >POS & Course Outcomes</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
										<hr />
										<Programoutcomes files={this.getFilesByCategory('courseoutcomes')} />
								</div> : null}
								
								{this.state.actieTab=='teachingplan' ? 
									<div>
										<h4 className="my-15" >POS & Teaching Plan</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
										<hr />
										<TeachingPlan files={this.getFilesByCategory('teachingplan')} />
								</div> : null}
								
								{this.state.actieTab=='awards' ? 
									<div>
										<h4 className="my-15" >Awards</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='highlights' ? 
									<div>
										<h4 className="my-15" >Highlights</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='activities' ? 
									<div>
										<h4 className="my-15" >Activities</h4>
										<div dangerouslySetInnerHTML={{__html: this.displayPageData()}} />
								</div> : null}
								
								{this.state.actieTab=='ematerial' ? 
									<div>
										<h4 className="my-15" >E-Material</h4>
										<Row>
											<Col md={12} >
												<ul className="collapsible mb-15 list-style-none" >
													{this.state.materialList.map((item,i)=>{
														return  <li onClick={()=>{this.setState({activeId: i})}} 
																className={this.state.activeId==i ? 'active' : ''} >
															<div className={this.state.activeId==i ? "collapsible-header active bg-light" : "collapsible-header"} >
																<h4 style={{fontSize: '15px', fontWeight: '600', textTransform: 'uppercase'}} >{this.getStaffName(item[0].staffid)}
																	<span className="pull-right">
																		{this.state.activeId==i ? 
																			<i className="ion-android-remove"></i> : 
																			<i className="ion-android-add"></i>
																		}
																	</span>
																</h4>
															</div>
															<div 
																className="collapsible-body cor-tim-tab"
																style={{display: this.state.activeId==i ? 'block' : 'none'}} >
																<ul>
																	{this.listMaterialsByStaff(item)}
																</ul>
															</div>
														</li>;
													})
													}
												</ul>
											</Col>
										</Row>
								</div> : null}
							
							</div>
							
							</div>
							}
							
						</Col>
						
						<Col md={3} >
							
							<Sidebar activeMenu={DT_Programmes} activeMenuTitle="PROGRAMMES" deptList={this.state.deptList} />
							
						</Col>
					</Row>
					
				</div>
				
				<ReactModal isOpen={this.state.showModal} >
					<div className="pdf__modal__header" >
						<div className="text-left" >
							<h4>Sarah Tucker College (Autonomous) - Tirunelveli</h4>
						</div>
						<div className="text-right" style={{marginTop: '-35px'}} >
							<button type="button" onClick={(e)=>{this.setState({showModal: false, fileUrl: ''})}} className="btn btn-danger" >Close</button>
						</div>
						<hr style={{marginTop: '5px', marginBottom: '0px'}} />
					</div>
					
					<object data={this.state.fileUrl} type="application/pdf" width="100%" height="850">
						<p>
							It appears your Web browser is not configured to display PDF files. No worries, just <a href="your_file.pdf">click here to download the PDF file.</a>
						</p>
					</object>
				</ReactModal>
				
				
			</section>
		);
	}
	
}
export default Department;

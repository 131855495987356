
const COOKIE_KEY = 'cb_ecom';

const Api = {
	/*Url :'http://localhost/kalvisalai/stc/php/api/',
	ImageUrl: 'http://localhost/kalvisalai/stc/php/',
	AcademicPath: 'https://admin.sarahtuckercollege.edu.in/api/'*/
	
	Url :'https://webadmin.sarahtuckercollege.edu.in/admin/api/',
	ImageUrl: 'https://webadmin.sarahtuckercollege.edu.in/admin/',
	AcademicPath: 'https://admin.sarahtuckercollege.edu.in/api/'
};


const DT_Academics = [
	{name: 'Programmes', link :'/programmes/ug'},
	{name: 'Syllabus', link :'/syllabus/ug'},
	{name: 'Course Outcomes', link :'/pso-and-course-outcomes/ug'},
	{name: 'Teaching Plan', link :'/teaching-plan'},
];

const DT_Programmes = [
	{name: 'UG Programmes', link :'/programmes/ug'},
	{name: 'PG Programmes', link :'/programmes/pg'},
	{name: 'M.Phil Programmes', link :'/programmes/mphil'},
	{name: 'Ph.D Programmes', link :'/programmes/phd'},
];

const DT_Syllabus = [
	{name: 'UG Syllabus', link :'/syllabus/ug'},
	{name: 'PG Syllabus', link :'/syllabus/pg'},
	{name: 'M.Phil Syllabus', link :'/syllabus/mphil'}
];

const DT_Courseoutcomes = [
	{name: 'UG Course Outcomes', link :'/pso-and-course-outcomes/ug'},
	{name: 'PG Course Outcomes', link :'/pso-and-course-outcomes/pg'},
	{name: 'M.Phil Course Outcomes', link :'/pso-and-course-outcomes/mphil'}
];



const DT_CourseMenu = [
	{name: 'About', link :'about'},
	{name: 'Vision & Mission', link :'vision'},
	{name: 'Courses Offered', link :'courses'},
	{name: 'Faculty', link :'faculty'},
	{name: 'Syllabus', link : 'syllabus'},
	{name: 'POS & Course Outcomes', link : 'courseoutcomes'},
	{name: 'POS & Teaching Plan', link : 'teachingplan'},
	{name: 'E-Material', link : 'ematerial'},
	{name: 'Awards', link : 'awards'},
	{name: 'Highlights', link : 'highlights'},
	{name: 'Activities', link : 'activities'},
];

const DT_QuickLinks = [
	{name: 'Scholarships', link :'/financial-aid'},
	{name: 'Grievances', link :'/grievances'},
	{name: 'Mandatory Disclosure', link :'mandatory-disclosure'},
	{name: 'Anti Ragging', link : 'anti-ragging'},
	{name: 'ICC', link : 'internal-complaint-committee'},
	{name: 'UBA', link : 'unnath-bharat-abhiyan'},	
	{name: 'Alumni', link : 'alumni'},
	{name: 'NIRF', link : 'nirf'},
];

export {
	COOKIE_KEY,
	Api,
	DT_Academics,
	DT_Programmes,
	DT_Syllabus,
	DT_Courseoutcomes,
	DT_CourseMenu,
	DT_QuickLinks
};

import {lazy} from 'react';

/*const Home = lazy(() => import('./pages/Home'));
const Contact = lazy(() => import('./pages/Contact'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Programmes = lazy(() => import('./pages/Programmes'));
const Syllabus = lazy(() => import('./pages/Syllabus'));
const Course = lazy(() => import('./pages/Course'));*/

import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Programmes from './pages/Programmes';
import Syllabus from './pages/Syllabus';
import Course from './pages/Course';
import Courseoutcomes from './pages/Courseoutcomes';
import Department from './pages/Department';
import Gallery from './pages/Gallery';
import GalleryList from './pages/Gallery/GalleryList';
import ERresources from './pages/ERresources';
import DeptResource from './pages/ERresources/DeptResource';
import TeachingPlan from './pages/TeachingPlan';
import DeptTeachingPlan from './pages/TeachingPlan/DeptTeachingPlan';

import SingleEvent from './pages/Events/SingleEvent';

import PdfViewer from './pages/PdfViewer';
import Empty from './pages/Page/Empty';

const pageList = [
	{ name: 'Home', path: '/', component: Home }, 
	{ name: 'Contact', path: '/contact', component: Contact },
	{ name: 'Not Found', path: '/404', component: NotFound }, 
	{ name: 'Programmes', path: '/programmes/:ptype', component: Programmes },
	{ name: 'Syllabus', path: '/syllabus/:ptype', component: Syllabus },
	{ name: 'Syllabus', path: '/course/:cid/:cname', component: Course },
	{ name: 'Course Outcomes', path: '/pso-and-course-outcomes/:ptype', component: Courseoutcomes },
	{ name: 'Department', path: '/department/:cid/:cname', component: Department },
	{ name: 'Department', path: '/pdf-file-viewer', component: PdfViewer },
	{ name: 'Gallery', path: '/gallery', component: Gallery },
	{ name: 'Gallery', path: '/gallery/:slug', component: GalleryList },
	{ name: 'Event', path: '/event/:slug', component: SingleEvent },
	{ name: 'E-Rresources', path: '/e-resources', component: ERresources },
	{ name: 'E-Rresources', path: '/e-resources/:id/:name', component: DeptResource },
	{ name: 'Teaching Plan', path: '/teaching-plan', component: TeachingPlan },
	{ name: 'Teaching Plan', path: '/teaching-plan/:id/:name', component: DeptTeachingPlan },
	{ name: 'Empty', path: '/empty', component: Empty },
	{ name: 'Not Found', path: '*', component: NotFound },
];
export default pageList;
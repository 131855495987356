import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, CategoryWidget, FeaturedProduct, ProductCollection, LineLoader, NewsScroll} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField} from '../../../utils';

import Founder from './Founder';
import HomeLinks from './HomeLinks';
import Vision from './Vision';
import VisionBox from './VisionBox';
import admissionGif from '../../../assets/img/admission.gif';
import Marquee from 'react-marquee';

class Home extends Component{
	constructor(props){
		super(props);
		this.state = {
			featureProducts: [],
			showModal: true,
		}
		
		this.hideModal = this.hideModal.bind(this);
	}
	
	componentDidMount(){
		//this.loadFeatureProducts();
	this.setState({showModal:true})	
	}
	
	loadFeatureProducts(){
		try{
			API.get('v1/site/item/featured/4').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({featureProducts: res['data'].data});
				}
			});						
		}
		catch(error){
			console.log(error);
		}
	}
	
	hideModal(){
		this.setState({showModal: false});
	}
	
	render(){
		
		
		return(
			<div>
				
				<Helmet>
					<title>{getCookieField('site','sitetitle')}</title>
				</Helmet>
				
				
				<Slider />
				
				{/*<Founder />
				
				<Vision />*/}
				
				<VisionBox />
				
				<HomeLinks />
				
				<NewsScroll />
				
				{/*
				<Modal show={this.state.showModal} onHide={this.hideModal} size="lg" >
					<Modal.Header closeButton  style={{backgroundColor:'#ff3f81'}}>
						
					</Modal.Header>
					<Modal.Body className="text-center" style={{backgroundColor:'#ffdbed'}}>
					<br />
					<Row>
						<Col md={3}></Col>
						<Col md={6}><a href="https://admission.sarahtuckercollege.edu.in">
						<img src={admissionGif} style={{width:'100%'}} />
						</a></Col>
					</Row>
					
						<hr />
						<Row>
						<Col md={12}>

						<marquee>
						
							<p style={{fontWeight: 'bolder', fontSize:'28px', color:'navy'}}>The classes for First Year UG students commences from 03-07-2024,Wednesday.</p>
							
							</marquee>
						
						</Col>
						</Row>
					{/*<Link to="/courses-offered" className="btn btn-primary" > View Courses</Link>*/}
					{/*
					</Modal.Body>
					</Modal>
				*/
			}

			</div>
		);
	}
	
}
export default Home;

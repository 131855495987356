import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl, getAscSortOrder} from '../../../utils';
import {CircleSlowLoader} from '../../../elements';
import axios from 'axios';

import CROWN_LOGO from '../../../assets/img/stc_logo_crown.png';

import Sidebar from './Sidebar';

class TeachingPlan extends Component{
	constructor(props){
		super(props);
		this.state = {
			deptList: [],
		}
	}
	
	componentDidMount(){		
		
		this.loadDeptList();
	}
	
	
	loadDeptList(){
		try{
			
			this.setState({showLoader: true, deptList: []});
			axios.post(Api.AcademicPath+'course/departments').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({
						deptList: res['data'].data,
						showLoader: false
					});
					
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn pt-30">
					
					<Row>
						<Col md={8} >
						
							{this.state.showLoader ? <CircleSlowLoader />
							
							:
							<div>
								 <div className="con-title">
									<h2>Teaching<span> Plan</span></h2>
								</div>
								
								<Row>
									{this.state.deptList.map((j,k)=>{
										var SRC = j.logopath=null || j.logopath==undefined || j.logopath=='' ? CROWN_LOGO : Api.ImageUrl+j.logopath;
										return <Col md={4} className="mb-15"  >
											<div className="shadow-hover-lg border border-2" >
												<div className="collapsible-body cor-tim-tab" style={{display:'block'}} >
													<Link to={"/teaching-plan/"+j.id+'/'+makeUrl(j.departmentname)} >
														<img src={SRC} style={{width:'100%'}} />
														<div className="text-center" style={{fontSize:'14px'}} >{j.departmentname}</div>
													</Link>
												</div>
											</div>
                                    </Col>;
									})
									}
                                </Row>
								
							</div>
							}
							
						</Col>
						
						<Col md={4} >
							
							<Sidebar activeMenu={DT_Programmes} activeMenuTitle="PROGRAMMES" />
							
						</Col>
					</Row>
					
				</div>
			</section>
		);
	}
	
}
export default TeachingPlan;

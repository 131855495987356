import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

import Page from '../Page';
import IMG_404 from '../../../assets/img/404.gif';

class NotFound extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			pageUrl: '',
			pageData: [],
		}
	}
	
	componentDidMount(){
		
		this.state.showLoader = true;
		this.state.pageUrl = this.props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
	}
	
	componentWillReceiveProps(props){
		
		this.state.pageUrl = props.match.params[0].replace(/^\/|\/$/g, '');
		
		this.loadPageContent();
		//this.forceUpdate();
	}
	
	loadPageContent(){
		try{
			
			this.setState({showLoader:true, pageData: []});
			API.get('site/v1/page/'+this.state.pageUrl).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({pageData: res['data'].data, showLoader:false});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
		
		return(
		<div  className="container pg-inn  pt-30 pb-50 ">
		
			{this.state.showLoader ? <CircleSlowLoader /> : null}

				{!this.state.showLoader && Object.keys(this.state.pageData).length<=0 ?
				<Row>	
					<Col md={12} >
						<div className="text-center" >
						  <img src={IMG_404} alt="404"   className="img-fluid rounded-circle mb-3 img-thumbnail " width="550" />
						  <h1> Error 404 page not found</h1>
						  <p>The page you were looking for doesn't exist.</p>
						  <a href="javascript:history.back()"  className="btn btn-danger rounded-pill"><i data-feather="arrow-left"></i> Go back</a>
					  </div>
					</Col>
				</Row>
				: 
				<Page pageData={this.state.pageData} /> }
		
		</div>
		);
	}
	
}
export default NotFound;

import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import {Api, DT_Programmes, DT_Syllabus, DT_Courseoutcomes} from '../../../utils/Variables';
import {getCookieField, capitalizeFirst, makeUrl, getAscSortOrder} from '../../../utils';

import MobMenu from './MobMenu';
import {FbLoader} from '../../../elements';

import Menu from '../Menu';


class TopMenu extends Component{
	constructor(props){
		super(props);
		this.state = {
			menuList: [],
			htmlStrTxt: [],
		}
		
		this.handleMenuHover = this.handleMenuHover.bind(this);
	}
	
	componentDidMount(){
		
		this.loadMenus();
		
	}
	
	getInnerStaticMenuList(menus){
		var rv = [];
		menus.map((item,i)=>{
			rv.push({children: [], linktype: "page", menuid: "0", menulink: item.link , menuorder: "0", parentid: "0", postid: "0" ,title: item.name});
		});
		return rv;
	}
	
	loadMenus(){
		try{
			
			this.setState({showLoader:true});
			API.get('v1/menus/1').then(res=>{
				if(res['data'].status=='1')
				{
					var d = [];//
					d.push({ children: [], linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Home"});
					
				var topChild = [];
				topChild.push({children: this.getInnerStaticMenuList(DT_Programmes) , linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Programmes"});
				
				topChild.push({children: this.getInnerStaticMenuList(DT_Syllabus), linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Syllabus"});
				
				topChild.push({children: this.getInnerStaticMenuList(DT_Courseoutcomes), linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Course Outcomes"});
				
				topChild.push({children: [], linktype: "page", menuid: "0", menulink: "/teaching-plan", menuorder: "0", parentid: "0", postid: "0" ,title: "Teaching Plan"});
				
				//topChild.push({children: [], linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Teaching Plan"});
				topChild.push({children: [], linktype: "page", menuid: "0", menulink: "/e-resources", menuorder: "0", parentid: "0", postid: "0" ,title: "E-Resources"});
				
				topChild.push({children: [], linktype: "page", menuid: "0", menulink: "/library", menuorder: "0", parentid: "0", postid: "0" ,title: "Library"});
				
				topChild.push({children: [], linktype: "page", menuid: "0", menulink: "/college-mou", menuorder: "0", parentid: "0", postid: "0" ,title: "College MOU"});
				
				
					
					//d.sort(getAscSortOrder('menuorder'));
					
					res['data'].data.map((item,i)=>{
						if(i==3)
							d.push({ children: topChild, linktype: "page", menuid: "0", menulink: "/", menuorder: "0", parentid: "0", postid: "0" ,title: "Academics"});
						
						d.push(item);
					});
					
					this.setState({showLoader: false, menuList: d});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleMenuHover(mId){
		try{	

			//document.getElementById(mId).style.display = 'block';
			var ele = document.getElementById(mId);
			$(ele).fadeIn();
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleMenuOut(mId){
		try{
			
			var ele = document.getElementById(mId);
			$(ele).fadeOut();
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	getInnerMenus(item){
		try{
			
		}
		catch(error){
			
		}
	}
	
	loadMenuTree(par, lvl){
		/* this.state.htmlStrTxt.push('<ul>');  
        for(var x in menuItems) {  
            if (menuItems[x].parentid == par) {  
                this.state.htmlStrTxt.push('<li><span class="level-' + lvl + '">' + menuItems[x].title + '</span>');  
                 
                if (menuItems[x].childCount > 0) {  
                    this.state.htmlStrTxt.push(recurseMenu(menuItems[x].id, lvl + 1));  
                }  
                this.state.htmlStrTxt.push('</li>');  
            }  
        }  
         this.state.htmlStrTxt.push('</ul>');  
		 return this.state.htmlStrTxt; */
	}
	
	 renderSubMenu(children){
		if (children && children.length > 0) {
		  return (
			<ul className="menu__submenu">
			  {this.renderMenuItems(children)}
			</ul>
		  );
		}
	  }
	  
	  renderMenuItems(items){
		  console.log(items);
		items.map((item, i) => {
		  const { menulink, title, children } = item;
		  return (
			<li className="menu__item" key={(`item-${i}-`)}>
			  <Link href={menulink} key={(`link-${i}-`)}>
				<a className="menu__link" target={menulink}>
				  {title}
				</a>
			  </Link>
			  {this.renderSubMenu(children)}
			</li>
		  );
		})
	}
	  
	render(){
		
	
		return(
		<div>
	
		<MobMenu logo={this.props.logo} menus={this.state.menuList} />
		
		<div className="top-logo" data-spy="affix" data-offset-top="250">
					<div className="container">
						
						<div className="row">
							<div className="col-md-12">
								
								<div className="main-menus">
								
								{this.state.showLoader ? <div style={{textAlign: 'center'}} > <FbLoader /> </div> : <Menu options={this.state.menuList} />}
								
								</div>
								
							</div>

						</div>
					</div>
				</div>
				
		</div>
		
		);
	}
	
}
export default TopMenu;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {CircleSlowLoader} from '../../../elements';
import Gallery from 'react-grid-gallery';

import SliderItem from './SliderItem';
import Events from './Events';

class NewsScroll extends Component{
	constructor(props){
		super(props);
		this.state = {
			dataList: [],
			showNewsLoader: false,
			upcomingList: [],
			galleryList: [],
		}
	}
	
	componentDidMount(){
		this.loadUpcomingNews();
		this.loadGalleryList();
	}
	
	loadGalleryList(){
		try{
			this.setState({showGalleryLoader: true});			
			API.get('site/v1/gallery/list/15').then(res=>{
				if(res['data'].status=='1') {
					this.setState({galleryList: res['data'].data, showGalleryLoader: false});
				}
				else {
					this.setState({showGalleryLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showGalleryLoader: false});
		}
	}
	
	loadUpcomingNews(){
		try{
			this.setState({showNewsLoader: true});
			var data = new FormData();
			data.append('type','upcoming');
			data.append('limit', '4');
			API.post('site/v1/news/list',data).then(res=>{
				
				if(res['data'].status=='1') {
					this.setState({upcomingList: res['data'].data, showNewsLoader: false});
				}
				else {
					this.setState({showNewsLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showNewsLoader: false});
		}
	}
	
	getImageList(){
		try{
			var rv = [];
			this.state.galleryList.map((item,i)=>{
				rv.push({src: Api.ImageUrl + item.filepath, thumbnail: Api.ImageUrl + item.filepath, thumbnailWidth: 130, thumbnailHeight: 90});
			});
			return rv;
		}
		catch(error){ return []; }
	}
	
	
	render(){
		
		
		return(
			 <section className="bg-light" >
				<div className="container pt-30 pb-50">
					<div className="row">
						<div className="con-title">
							<h2>News and <span>Events</span></h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="bot-gal h-gal ho-event-mob-bot-sp home_gallery">
								<h4>Photo Gallery</h4>
								{this.state.showGalleryLoader ? <CircleSlowLoader /> : 
									<Gallery images={this.getImageList()} />
								}
							</div>
							<Link to="/gallery">More Images..</Link>
						</div>
						<div className="col-md-4">
							<div className="bot-gal h-vid ho-event-mob-bot-sp">
								<h4>Video Gallery</h4>
								<iframe  height="170px" src="https://www.youtube.com/embed/NPmVna5Y8JA?si=Etb-ORuns7UY1IoJ" 
title="STC College  - BE THOU MY VISION" frameborder="0" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

								<iframe src="https://www.youtube.com/embed/RY-yyi_W3OU" height="170px" allowfullscreen></iframe>
								
								</div>
								<Link to="/videos">More Videos..</Link>

						</div>
						<div className="col-md-4">
							{this.state.showNewsLoader ?
								<div className="bot-gal h-blog ho-event" ><h4>Upcoming Events</h4><CircleSlowLoader /></div> : 
								<Events data={this.state.upcomingList} />
							}
						</div>
					</div>
				</div>
			</section>
		);
	}
	
}
export default NewsScroll;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';


class Copyright extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	render(){
		
		
		return(
			<section className="wed-rights">
				<div className="container">
					<div className="row">
						<div className="copy-right">
							<p>Copyrights &copy; 2018 . All rights reserved. Designed by <a href="http://peacesoft.in" target="_blank" style={{color: '#fff'}} >Peace Soft</a></p>
						</div>
					</div>
				</div>
			</section>
		);
	}
	
}
export default Copyright;
import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Carousel} from 'react-bootstrap';
import API from '../../../utils/API';
import {Api, DT_Academics, DT_Programmes} from '../../../utils/Variables';
import {upperCase, groupByMultiple, makeUrl} from '../../../utils';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Slide, Zoom  } from 'react-slideshow-image';

const styles = {
	sliderImg: {
		width: '100%',
		border: '1px solid black',
		borderRadius: '5px',
	}
}

class DeptSlider extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			imageList: [],
		}
	}
	
	componentDidMount(){
		this.loadSliders();
	}
	
	loadSliders(){
		try{
			var sid = this.props.deptId;
			API.get('site/v1/academic/slider/'+sid).then(res=>{
				if(res['data'].status=='1'){
					this.setState({imageList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
		}
	}
		
	render(){
		
		/*  var settings = {
		  dots: true,
		  infinite: true,
		  speed: 500,
		  slidesToShow: 1,
		  slidesToScroll: 1
		};
		
		return(
			 <Slider {...settings}>
				 {this.state.imageList.map((item,i)=>{
					return <div>
						<img src={Api.ImageUrl+item.filepath} style={{width: '100%'}} />
					</div>;					
				 })
				 }
			</Slider>
		); */
		
		const properties = {
		  duration: 5000,
		  transitionDuration: 500,
		  infinite: true,
		  indicators: true,
		  arrows: true,
		  onChange: (oldIndex, newIndex) => {
		  }
		}
		
		const zoomOutProperties = {
		  duration: 3000,
		  transitionDuration: 500,
		  infinite: true,
		  indicators: true,
		  scale: 0.4,
		  arrows: true
		}
				
		return (
		  <div className="slide-container">
		  {/*<Slide {...properties}>
				{this.state.imageList.map((item,i)=>{
					var imgUrl = Api.ImageUrl+item.filepath;
					return <div className="each-slide">
							<div style={{'backgroundImage': `url(${imgUrl})`, backgroundSize: '100% 100%'}}>
						</div>
					 </div>;			
				 })
				}
		  </Slide>*/}
		    <Zoom {...zoomOutProperties} autoplay={true} >
				{this.state.imageList.map((item,i)=>{
						var imgUrl = Api.ImageUrl+item.filepath;
						return <img src={Api.ImageUrl+item.filepath} style={styles.sliderImg} key={item.displayorder} />;		
					 })
				}
			 </Zoom>
		  </div>
		);

	}
	
}
export default DeptSlider;

import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {getCookieField, capitalizeFirst, makeUrl, href} from '../../../utils';
import ReactModal from 'react-modal';

import Copyright from './Copyright';
import FirstTimeModal from './FirstTimeModal';

class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			showModal: false,
		}
	}
	
	render(){		
		
		return(
			<section>
				
				{/*<FirstTimeModal />*/}
				
				  <section className="wed-hom-footer" style={{padding: '0px'}} >
					<div className="container">
						
						<div className="row wed-foot-link">
						{/*<div className="col-md-4 foot-tc-mar-t-o">
								<h4>Quick Links</h4>
								<ul className="list-style-none"  >
									<li><Link to="/programmes/ug">Courses Offered</Link></li>
									<li><Link to="/">Syllabus</Link></li>
									<li><Link to="/syllabus/ug">Admission</Link></li>
									<li><Link to="/history">College Profile</Link></li>
									<li><Link to="/office-of-the-coe">COE</Link></li>
									<li><Link to="/iqac---activities">IQAC</Link></li>
									<li><Link to="/feedback">Contact Us</Link></li>
								</ul>
						</div>*/}
							<div className="col-md-4">
								<h4>Research Links</h4>
								<ul className="list-style-none"  >
									<li><a href="https://www.academicroom.com/" target="_blank" >Academic Room</a></li>
									<li><a href="https://orcid.org/%20target=" target="_blank" >Orcid</a></li>
									<li><a href="https://scholar.google.co.in/schhp?hl=en" target="_blank" >Google Scholar</a></li>
									<li><a href="https://www.researchgate.net/" target="_blank" >Research Gate</a></li>
									<li><a href="http://www.researcherid.com/Home.action?returnCode=ROUTER.Unauthorized&Init=Yes&SrcApp=CR" target="_blank" >Researcher ID</a></li>
									<li><a href="https://ieeexplore.ieee.org/Xplore/home.jsp" target="_blank" >IEEE Xplore</a></li>
									<li><a href="https://www.researchpublish.com/" target="_blank" >Research Publish</a></li>
								</ul>
							</div>
							<div className="col-md-4">
								<h4>USEFUL LINKS</h4>
								<ul className="list-style-none"  >
									<li><a href="http://www.msuniv.ac.in/" target="_blank" >MS University</a></li>
									<li><a href="http://www.ugc.ac.in/" target="_blank" >UGC</a></li>
									<li><a href="http://www.naac.gov.in/" target="_blank" >NAAC</a></li>
									<li><a href="http://www.dst.gov.in/" target="_blank" >DST</a></li>
									<li><a href="http://www.dbtindia.nic.in/" target="_blank" >DBT</a></li>
									<li><a href="http://epgp.inflibnet.ac.in/" target="_blank" >Open Education Resource</a></li>
									{/*<li><a href="https://swayam.gov.in/" target="_blank" >Online Courses</a></li>*/}
									<li><Link to="/online-courses" >Online Courses</Link></li>
									<li><Link to="/unnath-bharat-abhiyan" >UBA</Link></li>
								</ul>
							</div>
							<div className="wed-foot-link-1 col-md-4">
								<h4>Get In Touch</h4>
								<p>Address: {getCookieField('site','address')},{getCookieField('site','city')},{getCookieField('site','state')},{getCookieField('site','country')} - {getCookieField('site','pincode')}</p>
								<p>Phone: <a href="javascript:;">{getCookieField('site','phone')}</a> 
								&emsp;
								<a style={{fontWeight: '600', color: '#fff'}} href="javascript:;" onClick={(e)=>{this.setState({showModal: true})}} >View in Google Map</a></p>
								<p>Email: <a href="javascript:;">{getCookieField('site','email')}</a></p>
								<ul className="list-style-nones"  >
									<li><a href={href(getCookieField('site','facebook'))} ><i className="ion-social-facebook" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','twitter'))} ><i className="ion-social-twitter" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','gplus'))} ><i className="ion-social-googleplus" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','youtube'))} ><i className="ion-social-youtube" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','whatsapp'))} ><i className="ion-social-tumblr" aria-hidden="true"></i></a>
									</li>
									<li>
										<a href="https://www.hitwebcounter.com" target="_blank">
<img src="https://hitwebcounter.com/counter/counter.php?page=7193104&style=0006&nbdigits=7&type=ip&initCount=101252" title="Free-Counter" Alt="hitwebcounter"   border="0" />
</a>                                    
									</li>
								</ul>
							</div>
						</div>
						{/*<div className="row wed-foot-link-1">
							<div className="col-md-8 foot-tc-mar-t-o">
								<h4>About Sarah Tucker College</h4>
								<div style={{color:'#838384', fontSize:'13px', lineHeight: '20px'}} >
									The Sarah Tucker Institutions are to be remembered as the nursery of some pioneer institutions for the education of the handicapped in the whole of India. After three decades of resolute spadework, the Upper Primary Boarding School attached to the Training Institution was upgraded as the ‘Sarah Tucker High School;’ in 1890 with 5 Girl students
								</div>
							</div>
							<div className="col-md-4">
								<h4>Get In Touch</h4>
								<p>Address: {getCookieField('site','address')},{getCookieField('site','city')},{getCookieField('site','state')},{getCookieField('site','country')} - {getCookieField('site','pincode')}</p>
								<p>Phone: <a href="javascript:;">{getCookieField('site','phone')}</a></p>
								<p>Email: <a href="javascript:;">{getCookieField('site','email')}</a></p>
								<ul className="list-style-none"  >
									<li><a href={href(getCookieField('site','facebook'))} ><i className="ion-social-facebook" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','twitter'))} ><i className="ion-social-twitter" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','gplus'))} ><i className="ion-social-googleplus" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','youtube'))} ><i className="ion-social-youtube" aria-hidden="true"></i></a>
									</li>
									<li><a href={href(getCookieField('site','whatsapp'))} ><i className="ion-social-tumblr" aria-hidden="true"></i></a>
									</li>
								</ul>
							</div>
	</div>*/}
					</div>
				</section>
				
				<Copyright />
				
				{/* <ReactModal isOpen={this.state.showModal} >
					<div className="pdf__modal__header" >
						<div className="text-left" >
							<h4>Sarah Tucker College (Autonomous) - Tirunelveli</h4>
						</div>
						<div className="text-right" style={{marginTop: '-35px'}} >
							<button type="button" onClick={(e)=>{this.setState({showModal: false, fileUrl: ''})}} className="btn btn-danger" >Close</button>
						</div>
						<hr style={{marginTop: '5px', marginBottom: '0px'}} />
					</div>
					
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.9239139693345!2d77.73851541410323!3d8.698775696470241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b041274461bb63d%3A0x5bb591deb6a0c2c2!2sSarah%20Tucker%20College!5e0!3m2!1sen!2sin!4v1582369668124!5m2!1sen!2sin" width="100%" height="520" frameborder="0" style={{border:'0'}} allowfullscreen=""></iframe>
					
	</ReactModal> */}
				
			</section>
		);
	}
	
}
export default Footer;
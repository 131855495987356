import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Programmes} from '../../../utils/Variables';
import API from '../../../utils/API';
import {upperCase, groupByMultiple, makeUrl, getAscSortOrder} from '../../../utils';
import {CircleSlowLoader} from '../../../elements';
import axios from 'axios';
import ReactModal from 'react-modal';

import CROWN_LOGO from '../../../assets/img/stc_logo_crown.png';

import Sidebar from './Sidebar';

class DeptTeachingPlan extends Component{
	constructor(props){
		super(props);
		this.state = {
			courseList: [],
			deptList: [],
			employeeList: [],
			dataList: [],
			dataView: [],
			deptId: this.props.match.params.id,
			activeId: '',
			showModal: false,
			fileUrl: '',
		}
	}
	
	componentDidMount(){		
		
		this.loadDeptInfo();
		this.loadDeptList();
		this.loadMaterialList();
	}
	
	
	loadDeptInfo(){
		try{
			
			this.setState({showLoader: true, courseList: []});
			axios.post(Api.AcademicPath+'course/coursebydept/'+this.state.deptId).then(res=>{
				if(res['data'].status=='1')
				{
					
					this.setState({
						courseList: res['data'].data,
						employeeList: res['data'].employees,
						showLoader: false
					});
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	

	loadDeptList(){
		try{
			
			this.setState({showLoader: true, deptList: []});
			axios.post(Api.AcademicPath+'course/departments').then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({
						deptList: res['data'].data,
						showLoader: false
					});
					
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadMaterialList(){
		try{
			
			this.setState({showLoader: true, dataList: []});
			API.get('site/v1/teachingplan/'+this.state.deptId).then(res=>{
				if(res['data'].status=='1')
				{
					var d = groupByMultiple(res['data'].data,function(obj){
						return[obj.academicyearid];
					});
					this.setState({
						dataList: res['data'].data,
						dataView: d,
						showLoader: false
					});
					
				}
				else
				{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getStaffName(id){
		try{
			var d = this.state.employeeList.filter(function(obj){
				return(obj.id==id);
			});
			
			return Object.keys(d).length>0 ? d[0].empname+' '+d[0].qualification : '';
		}
		catch(error){
			return '';
		}
	}
	
	listMaterialsByAcyear(item){
		try{
			var rv = [];
			var d = item;/* this.state.dataList.filter(function(obj){
				return(obj.staffid==id);
			});
			*/
			d.map((item,i)=>{
				rv.push(<li key={i} style={{marginBottom: '5px', fontSize: '17px'}} >
					{/*<a href={Api.ImageUrl + item.filepath} target="_blank" >*/}
					<a href="javascript:;" onClick={this.handleLinkClick.bind(this, Api.ImageUrl + item.filepath)} >
						{item.filetitle}
					</a>
				</li>);
			});
			
			return rv;
		}
		catch(error){
			return '';
		}
	}
	
	componentWillReceiveProps(props){
		
		this.state.deptId = props.match.params.id;
		this.loadDeptInfo();
		this.loadMaterialList();
	}
	
	handleLinkClick(item){
		
		this.setState({fileUrl: encodeURI(item), showModal: true});
	}
	
	render(){
		
		
		return(
			  <section>
				<div className="container pg-inn pt-30">
					
					<Row>
						<Col md={8} >
						
							{this.state.showLoader ? <CircleSlowLoader />
							
							:
							<div>
								 <div className="con-title">
									<h2>Teaching Plan for <span>{Object.keys(this.state.courseList).length >0 ? this.state.courseList[0].departmentname : ''}</span></h2>
								</div>
																
								<Row>
									<Col md={12} >
										<ul className="collapsible mb-15 list-style-none" >
											{this.state.dataView.map((item,i)=>{
												return  <li onClick={()=>{this.setState({activeId: i})}} 
														className={this.state.activeId==i ? 'active' : ''} >
													<div className={this.state.activeId==i ? "collapsible-header active bg-light" : "collapsible-header"} >
														<h4>{upperCase(item[0].academicyear)} 
															<span className="pull-right">
																{this.state.activeId==i ? 
																	<i className="ion-android-remove"></i> : 
																	<i className="ion-android-add"></i>
																}
															</span>
														</h4>
													</div>
													<div 
														className="collapsible-body cor-tim-tab"
														style={{display: this.state.activeId==i ? 'block' : 'none'}} >
														<ul className="list-style-none" >
															{this.listMaterialsByAcyear(item)}
														</ul>
													</div>
												</li>;
											})
											}
										</ul>
									</Col>
								</Row>
								
							</div>
							}
							
						</Col>
						
						<Col md={4} >
							
							<Sidebar activeMenu={this.state.deptList} />
							
						</Col>
					</Row>
					
				</div>
				
				<ReactModal isOpen={this.state.showModal} >
					<div className="pdf__modal__header" >
						<div className="text-left" >
							<h4>Sarah Tucker College (Autonomous) - Tirunelveli</h4>
						</div>
						<div className="text-right" style={{marginTop: '-35px'}} >
							<button type="button" onClick={(e)=>{this.setState({showModal: false, fileUrl: ''})}} className="btn btn-danger" >Close</button>
						</div>
						<hr style={{marginTop: '5px', marginBottom: '0px'}} />
					</div>
					
					<object data={this.state.fileUrl} type="application/pdf" width="100%" height="850">
						<p>
							It appears your Web browser is not configured to display PDF files. No worries, just <a href="your_file.pdf">click here to download the PDF file.</a>
						</p>
					</object>
				</ReactModal>
			
			
			</section>
		);
	}
	
}
export default DeptTeachingPlan;

import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';

class Featured extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<div className="card card-product">
				 <div  className="card-body">
					<button  className="wishlist atw-demo active" title="Added to wishlist">
						<i className="lni-heart"></i>
					</button>
					
					<a href={this.props.url}>
						<img  className="card-img-top" src={this.props.image} alt={this.props.title} />
					</a>
					<a href={this.props.url} className="card-title">{this.props.title}</a>
					<span  className="badge badge-success">New arrival</span>
					<div  className="price"><span  className="h5">{this.props.amount}</span></div>
				  </div>
				  <div  className="card-footer">
					<button  className="btn btn-sm rounded-pill btn-outline-primary btn-block atc-demo">
						Add to Cart
					</button>
				  </div>
			</div>
		);
	}
	
}
export default Featured;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api, DT_Academics, DT_Syllabus} from '../../../utils/Variables';
import {upperCase, groupByMultiple, getDescSortOrder} from '../../../utils';
import API from '../../../utils/API';
import {CircleSlowLoader} from '../../../elements';

import Sidebar from './Sidebar';

class Page extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	getField(fieldName){
		try{
			
			var d = this.props.pageData;
			return d[fieldName];
		}
		catch(error){
			console.log(error);
			return null;
		}
	}
	
	render(){
		
		
		return(
			  <section>
				
				{this.getField('pagetemplate')=='withsidebar' ? 
				<Row>
					<Col md={9} >
						<div className="cor-p1 " style={{marginTop: '5px', marginBottom: '15px', paddingBottom: '5px', paddingTop: '0px'}} >
							<h3 className="mt-0">{this.getField('pagetitle')}</h3>
							{/*<div className="share-btn">
								<ul>
									<li><a href="#"><i className="fa fa-facebook fb1"></i> Share On Facebook</a>
									</li>
									<li><a href="#"><i className="fa fa-twitter tw1"></i> Share On Twitter</a>
									</li>
									<li><a href="#"><i className="fa fa-google-plus gp1"></i> Share On Google Plus</a>
									</li>
								</ul>
							</div>*/}
						</div>
						
						<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />
					</Col>
					<Col md={3} >
						
						<Sidebar />
						
					</Col>
				</Row>
				:
				<Row>
					<Col md={12} >
						<div className="cor-p1 " style={{marginTop: '5px', marginBottom: '15px', paddingBottom: '5px', paddingTop: '0px'}} >
							<h3 className="mt-0">{this.getField('pagetitle')}</h3>
							{/*<div className="share-btn">
								<ul>
									<li><a href="#"><i className="fa fa-facebook fb1"></i> Share On Facebook</a>
									</li>
									<li><a href="#"><i className="fa fa-twitter tw1"></i> Share On Twitter</a>
									</li>
									<li><a href="#"><i className="fa fa-google-plus gp1"></i> Share On Google Plus</a>
									</li>
								</ul>
							</div>*/}
						</div>
						<div dangerouslySetInnerHTML={{__html: this.getField('pagecontent')}} />
					</Col>
				</Row>
				}
				
			</section>
		);
	}
	
}
export default Page;
